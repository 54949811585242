import drag from './drag';

const install = function install(Vue) {
  Vue.directive('dialogDrag', drag);
};

if (typeof window !== 'undefined' && window.Vue) {
  window.dialogDrag = drag;
}

drag.install = install;

export default drag;
