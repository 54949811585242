export default [
  {
    path: 'ticket-list',
    component: () => import(/* webpackChunkName: "ticket-list" */ '@/views/ticket/index.vue'),
    name: 'ticket-list',
    meta: { title: '订单管理', roles: ['ticket-list', 'ticket-list-fx'], clearSearch: true },
  },
  {
    path: 'ticket-public',
    component: () => import(/* webpackChunkName: "ticket-public" */ '@/views/ticket/index.vue'),
    name: 'ticket-public',
    meta: { title: '公版订单', roles: ['ticket-public'], clearSearch: true },
  },
  {
    path: 'ticket-not-deal',
    component: () => import(/* webpackChunkName: "ticket-public" */ '@/views/ticket/index.vue'),
    name: 'ticket-not-deal',
    meta: { title: '未成交订单', roles: ['ticket-not-deal'], clearSearch: true },
  },
  {
    path: 'ticket-public',
    component: () => import(/* webpackChunkName: "ticket-list-recharge" */ '@/views/ticket/index.vue'),
    name: 'ticket-public',
    meta: { title: '储值订单', roles: ['ticket-list-recharge'], clearSearch: true },
  },
  {
    path: 'ticket-birth',
    component: () => import(/* webpackChunkName: "ticket-birth" */ '@/views/ticket/index.vue'),
    name: 'ticket-birth',
    meta: { title: '预付多选', roles: ['ticket-birth'], clearSearch: true },
  },
  {
    path: 'ticket-thank',
    component: () => import(/* webpackChunkName: "ticket-thank" */ '@/views/ticket/index.vue'),
    name: 'ticket-thank',
    meta: { title: '礼品卡册', roles: ['ticket-thank'], clearSearch: true },
  },
  {
    path: 'ticket-express',
    component: () => import(/* webpackChunkName: "express" */ '@/views/express/index.vue'),
    name: 'ticket-express',
    meta: { title: '快递公司管理', roles: ['ticket-express'] },
  },
  {
    path: 'ticket-holiday',
    component: () => import(/* webpackChunkName: "holiday" */ '@/views/holiday/index.vue'),
    name: 'ticket-holiday',
    meta: { title: '节假日管理', roles: ['ticket-holiday'] },
  },
  {
    path: 'ticket-sms',
    component: () => import(/* webpackChunkName: "sms" */ '@/views/sms/index.vue'),
    name: 'ticket-sms',
    meta: { title: '短信模版管理', roles: ['ticket-sms'] },
  },
];
