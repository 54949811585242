<template>
  <el-form
    ref="lzForm"
    data-flex="cross:center"
    class="lz-form__container"
    :size="size"
    @submit.native.prevent
    :label-width="labelWidth + 'px'">
    <template v-for="(element, index) in form">
      <lz-form-item
        @keyup-enter="handleKeyupEnter"
        @change-select="handleChangeSelect"
        @change-input="handleChangeInput"
        @switch-change="handleSwitchChange"
        @tree-node-click="handleTreeNodeClick"
        :style="{ width: element.width || width }"
        ref="formItem"
        :disabled="disabled"
        :element="element"
        :key="index">
      </lz-form-item>
    </template>
  </el-form>
</template>

<script>
import { formGetValue } from '@/utils/form';

export default {
  name: 'lzForm',
  props: {
    labelWidth: {
      type: Number,
      default: 100,
    },
    // 一行几列
    col: {
      type: Number,
      default: 3,
    },
    form: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'small',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    width() {
      return `calc(100% / ${this.col})`;
    },
  },
  methods: {
    getValue(ops = {}) {
      const {
        isValidate = true, // 是否需要验证
      } = ops;
      const { params, error } = formGetValue(this.form);
      if (isValidate && error.length > 0) {
        this.$message.error(`${error[0].name}格式有误`);
        return null;
      }
      return params;
    },
    setValue(ops = {}) {
      const {
        url,
        data,
      } = ops;
      if (data) {
        this.insertValue(data);
        return null;
      }
      return new Promise((resolve) => {
        this.$baseHttp.get(url).then((response) => {
          this.insertValue(response.data);
          resolve(response.data);
        });
      });
    },
    insertValue(response) {
      this.form.forEach((element) => {
        // 有可能返回的数据不一致
        let value = element.responseName ? response[element.responseName] : response[element.fieldName];
        if (value === undefined) value = typeof element.value === 'string' ? '' : [];
        if (Array.isArray(element.value)) {
          // 有可能返回的数据就是数组
          if (typeof value === 'object' && element.type === 'select') {
            value = value.map((r) => String(r));
          }
          if (typeof value !== 'object' && element.type === 'select') {
            value = value.split(',');
          }
          if (element.fieldName.indexOf('||') >= 0) {
            value = [];
            const names = element.fieldName.split('||');
            names.forEach(((key) => {
              if (response[key] !== undefined && response[key] !== 0 && response[key] !== '') value.push(response[key]);
            }));
          }
          this.$set(element, 'value', value || []);
          return;
        }
        if (element.type === 'switch') {
          this.$set(element, 'value', value !== undefined ? value : false);
          return;
        }
        this.$set(element, 'value', value !== undefined ? String(value) : '');
      });
    },
    resertForm() {
      this.form.forEach((el, index) => {
        this.$refs.formItem[index].init();
      });
    },
    handleKeyupEnter() {
      this.$emit('keyup-enter');
    },
    handleChangeSelect(element) {
      this.$emit('change-select', element, this.form);
    },
    handleChangeInput(element) {
      this.$emit('change-input', element, this.form);
    },
    handleTreeNodeClick(node, element) {
      this.$emit('tree-node-click', node, element);
    },
    handleSwitchChange(element) {
      this.$emit('switch-change', element);
    },
  },
};
</script>

<style lang="scss" scoped>
.lz-form__container {
  flex-wrap: wrap;
  ::v-deep .editor .el-form-item__label {
    display: none;
  }
  ::v-deep .upload {
    &.uploda_multiple_true .el-form-item__label {
      display: none;
    }
  }
}
</style>
