<template>
  <el-pagination
    background
    v-if="total > 0"
    class="mt-20"
    :layout="layout"
    data-flex="main:center"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[20, 40, 60, 80, 100]"
    :page-size="pageSize"
    :total="total">
  </el-pagination>
</template>

<script>
import { getSearchValue } from '@/utils/search';

export default {
  name: 'lzPagination',
  props: {
    search: {
      type: Array,
      default: () => [],
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper',
    },
    // 判断是否在路由显示分页信息
    isRouter: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    total() {
      const element = this.getElement('page_total');
      return element ? Number(element.value) : 0;
    },
    pageSize() {
      const element = this.getElement('page_size');
      return element ? Number(element.value) : 20;
    },
    currentPage() {
      const element = this.getElement('page');
      return element ? Number(element.value) : 1;
    },
  },
  data() {
    return {};
  },
  methods: {
    getElement(fieldName) {
      return this.search.find((r) => r.fieldName === fieldName);
    },
    setRouter(type, val) {
      if (this.isRouter) {
        const query = getSearchValue(this.search);
        query[type] = val;
        if (type === 'page_size') query.page = 1;
        this.$router.push({ query });
      }
      this.$emit('change-url');
    },
    handleSizeChange(val) {
      const element = this.getElement('page_size');
      if (element) this.$set(element, 'value', val);
      this.setRouter('page_size', val);
    },
    handleCurrentChange(val) {
      const element = this.getElement('page');
      if (element) this.$set(element, 'value', val);
      this.setRouter('page', val);
    },
  },
};
</script>
<style lang="scss" scoped></style>
