<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style rel="stylesheet/scss" lang="scss">

</style>
