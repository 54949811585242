const state = () => ({
  sidebar: localStorage.getItem('sidebar') || '',
  cachedViews: [],
  visitedMenu: [
    {
      name: 'index',
      title: '首页',
      path: '/index',
    },
  ],
});

const getters = {
  sidebar: (state) => state.sidebar,
  cachedViews: (state) => state.cachedViews,
  visitedMenu: (state) => state.visitedMenu,
};

const mutations = {
  SIDEBAR_INIT: (state, value) => {
    state.sidebar = value;
  },
  CACHAE_INIT: (state, value) => {
    state.cachedViews.push(value);
  },
  MENU_INIT: (state, value) => {
    const visitedMenu = state.visitedMenu;
    const el = visitedMenu.find((r) => r.name === value.name);
    // 解决列表搜索 path 不同新打开了一个窗口
    if (el && value.type === '') {
      el.path = value.path;
      return;
    }
    const isInMenu = visitedMenu.some((r) => r.path === value.path);
    if (!isInMenu) visitedMenu.push(value);
  },
  MENU_CHANGE: (state, data) => {
    const { type, value } = data;
    const views = state.visitedMenu;
    const index = views.findIndex((r) => r.path === value.fullPath);
    switch (type) {
      case 'other': // 关闭其他
        state.visitedMenu = views.filter((r) => r.name === 'index' || (r.path === value.fullPath && r.name === value.name));
        break;
      case 'right': // 关闭右侧
        views.splice(index + 1, views.length - 1);
        break;
      case 'left': // 关闭左侧
        views.splice(1, index - 1);
        break;
      case 'all': // 关闭所有
        views.splice(1);
        break;
      default: // 单个关闭
        views.splice(value, 1);
        break;
    }
  },
};

const actions = {
  setChangeSideBar({ commit }, value) {
    commit('SIDEBAR_INIT', value);
    localStorage.setItem('sidebar', value);
  },
  setChangeTags({ commit }, value) {
    commit('MENU_INIT', value);
  },
  setCloseTags({ commit }, data) {
    commit('MENU_CHANGE', data);
  },
  setCache({ commit }, value) {
    commit('CACHAE_INIT', value);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
