<template>
  <div v-if="!item.hidden">
    <!-- <template v-if="item.name === 'index-layout'">
      <el-menu-item index="/index">
        <i class="el-icon-location" v-if="item.meta.icon"></i>
        <span slot="title">首页</span>
      </el-menu-item>
    </template> -->
    <template
      v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren)">
      <template v-if="item.meta && item.meta.title">
        <el-menu-item :index="resolvePath(onlyOneChild.path)">
          <i :class="item.meta.icon" v-if="item.meta.icon"></i>
          <span slot="title">{{ item.meta.title }}</span>
        </el-menu-item>
      </template>
    </template>
     <el-submenu v-else :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title">
        <i :class="item.meta.icon" v-if="item.meta.icon"></i>
        <span slot="title">{{ item.meta.title }}</span>
      </template>
      <el-menu-item-group>
        <sidebar-item
          v-for="child in item.children"
          :key="child.path"
          :item="child"
          :level="level + 1"
          :class="'level_menu_'+(level+1)"
          :base-path="resolvePath(child.path)"
          class="nest-menu" />
      </el-menu-item-group>
    </el-submenu>
  </div>
</template>
<script>
import path from 'path';

export default {
  name: 'sidebarItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    basePath: {
      type: String,
      default: '',
    },
    level: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      onlyOneChild: null,
    };
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) return false;
        this.onlyOneChild = item;
        return true;
      });
      if (parent.alwaysShow || showingChildren.length === 0) {
        this.onlyOneChild = {
          ...parent,
          path: parent && parent.children && parent.children.length > 0 ? parent.children[0].path : '',
          noShowingChildren: true,
        };
        return true;
      }
      return false;
    },
    resolvePath(routePath) {
      return path.resolve(this.basePath, routePath);
    },
  },
};
</script>
<style lang="scss" scoped></style>
