<template>
  <div class="lz-list" :class="{'is-table-flex': this.tableHeight}">
    <lz-search
      @change-url="getData"
      @upload-success="handleUploadSuccess"
      @search-btn-click="handleSearchBtnsClick"
      :search-btn="searchBtn"
      :is-router="isRouter"
      :show-search="showSearch"
      :search="search">
    </lz-search>
    <div class="lz-table" style="flex: 1">
      <lz-table-list
        :loading="loading"
        :list="list"
        :selection="selection"
        :table-height="tableHeight"
        :is-tree="isTree"
        :default-expand-all="defaultExpandAll"
        :row-key="rowKey"
        :table="table"
        @table-btn-click="handleTableBtnClick"
        @table-checkbox-change="handleCheckboxChange">
      </lz-table-list>
    </div>
    <div data-flex="main:center cross:center">
      <div class="mt-20 f12">
        <div v-if="tableSelect.length > 0">
          已选择
          <span class="color-primary">{{ tableSelect.length }}</span>
          条数据
        </div>
        <slot name="pagePosition"></slot>
      </div>
      <template v-if="!isRouter">
        <lz-pagination
          data-flex-box="1"
          class="mt-20"
          @change-url="getData"
          :search="search"
          :is-router="false">
        </lz-pagination>
      </template>
      <template v-else>
        <lz-pagination
          data-flex-box="1"
          @change-url="getData"
          :search="search">
        </lz-pagination>
      </template>
    </div>
  </div>
</template>
<script>
import { getList, setSearchValue } from '@/utils/search';

export default {
  name: 'lzList',
  props: {
    search: {
      type: Array,
      default: () => [],
    },
    table: {
      type: Array,
      default: () => [],
    },
    tableHeight: {
      default: null,
    },
    config: {
      default: () => {},
      type: Object,
    },
    isTree: { // 是否层级树
      default: false,
      type: Boolean,
    },
    defaultExpandAll: {
      default: true,
      type: Boolean,
    },
    searchBtn: {
      type: Array,
      default: () => [],
    },
    rowKey: {
      default: 'id',
      type: String,
    },
    showSearch: {
      default: true,
    },
    selection: {
      default: false,
      type: Boolean,
    },
    // 路由配置从路由上获取参数
    isRouter: {
      default: true,
      type: Boolean,
    },
    searchFomate: {
      type: Function,
    },
  },
  data() {
    return {
      list: [],
      loading: false,
      tableSelect: [],
      pageSizeRouter: ['ticket-list', 'order', 'ticket-list-fx'],
    };
  },
  computed: {},
  watch: {
    $route: function change(val) {
      if (val.query.page || !this.isRouter) return;
      // 解决地址栏没参数重置所有信息
      this.search.forEach((element) => {
        switch (element.fieldName) {
          case 'page':
            this.$set(element, 'value', 1);
            break;
          case 'page_size':
          case 'page_total':
            this.$set(element, 'value', 0);
            if (element.fieldName === 'page_size' && this.pageSizeRouter.includes(this.$route.name)) {
              this.$set(element, 'value', 100);
            }
            break;
          default:
            this.$set(element, 'value', '');
            break;
        }
      });
      this.getData();
    },
  },
  mounted() {
    const hasPage = this.search.some((r) => r.fieldName === 'page');
    if (this.isRouter) {
      if (!this.config.url) {
        this.$message.error('未配置URL地址');
        return;
      }
      if (!hasPage) {
        this.search.push(
          { fieldName: 'page', value: 1 },
          { fieldName: 'page_size', value: this.pageSizeRouter.includes(this.$route.name) ? 60 : 20 },
          { fieldName: 'page_total', value: 0, noRouter: true },
        );
      }
      setSearchValue(this.search);
      this.getData();
      return;
    }
    if (!hasPage) {
      this.search.push(
        { fieldName: 'page', value: 1 },
        { fieldName: 'page_size', value: 10 },
        { fieldName: 'page_total', value: 0 },
      );
    }
  },
  methods: {
    async getData() {
      if (this.isRouter && !this.tableHeight) {
        setTimeout(() => {
          document.getElementsByClassName('el-main')[0].scrollTop = 0;
        });
      } else {
        this.list = [];
      }
      this.loading = true;
      const response = await getList(this.config.url, this.search, this.isTree, this.searchFomate);
      this.$emit('getdata', response);
      this.loading = false;
      // 按钮有可能在外层判断
      this.setHtmlButton(response.data);
      if (this.config.setTableFormat) {
        this.list = this.config.setTableFormat(response.data, response);
        return;
      }
      this.list = response.data;
    },
    setHtmlButton(response) {
      response.forEach((el) => {
        this.$set(el, 'htmlButton', []);
        if (el.is_edit) {
          el.htmlButton.push({
            name: '详情',
            key: 'edit',
            type: 'success',
          });
        }
        if (el.is_del) {
          el.htmlButton.push({
            name: '删除',
            key: 'delete',
            type: 'danger',
          });
        }
        if (el.children) this.setHtmlButton(el.children);
      });
    },
    getOneData(target, params) {
      this.$baseHttp.get(this.config.url, { params }).then((response) => {
        if (response.data.data.length === 0) return;
        let element;
        this.setHtmlButton(response.data.data);
        if (this.config.setTableFormat) {
          element = this.config.setTableFormat(response.data.data)[0];
        } else {
          element = response.data.data[0];
        }
        Object.keys(element).forEach((key) => {
          this.$set(target, key, element[key]);
        });
      });
    },
    /**
     * 导入excel
     * @param {response} {Object} 数据对象
    */
    handleUploadSuccess(response) {
      this.$emit('upload-success', response);
    },
    /**
     * 表格操作栏按钮点击
     * @param {type} {String} 类型
     * @param {scope} {Object} 数据对象
    */
    handleTableBtnClick(type, scope, index) {
      this.$emit('table-btn-click', type, scope, index);
    },
    /**
     * 菜单返回按钮点击
     * @param {type} {String} 类型
    */
    handleSearchBtnsClick(type) {
      this.$emit('search-btn-click', type);
    },
    /**
     * 表格多选改变事件
     * @param {scope} {value} 数据对象
    */
    handleCheckboxChange(value) {
      this.tableSelect = value;
      this.$emit('table-checkbox-change', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.is-table-flex {
  height: calc(100vh - 190px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
</style>
