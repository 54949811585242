<template>
   <lz-dialog
    title="产品列表"
    :is-close-btn="false"
    ref="lzFormDialog">
    <lz-list
      :config="config"
      :table="table"
      @table-btn-click="handleCommonBtnCLick"
      :is-router="false"
      :search="search"
      ref="lzList">
    </lz-list>
  </lz-dialog>
</template>
<script>
export default {
  props: {
    list: {
      default: () => [],
    },
    goods: {
      default: () => [],
    },
    info: {
      default: () => {},
    },
  },
  data() {
    return {
      config: {
        url: '/goods/list',
        setTableFormat: (list) => {
          list.forEach((el) => {
            this.$set(el, 'htmlButton', []);
            if (this.$route.name !== 'order-shipping' || (this.$route.name === 'order-shipping' && el.memo === '')) {
              this.$set(el, 'htmlButton', [
                {
                  name: '确定',
                  key: 'enter',
                  type: 'primary',
                },
              ]);
            }
          });
          return list;
        },
      },
      search: [
        { name: '名称/型号', type: 'text', fieldName: 'keyword', value: '' },
        {
          name: '品牌',
          type: 'select',
          fieldName: 'brand_id',
          value: '',
          remote: true,
          multiple: true,
          collapseTags: true,
          width: '180px',
          props: {
            url: '/brand/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '用途',
          type: 'select',
          fieldName: 'purpose_id',
          value: '',
          remote: true,
          props: {
            url: '/purpose/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '价格区间',
          type: 'select',
          fieldName: 'price_range_id',
          value: '',
          remote: true,
          props: {
            url: '/price-range/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '场景',
          type: 'select',
          fieldName: 'scene_id',
          value: '',
          remote: true,
          props: {
            url: '/scene/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '供应商',
          type: 'select',
          fieldName: 'supplier_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/supplier/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '价格',
          type: 'select',
          fieldName: 'price_title_id',
          value: '',
          remote: true,
          props: {
            url: '/price-title/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'cate_id',
          type: 'treeSelect',
          name: '品类',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/cate/list',
            value: 'id',
            label: 'name',
            params: { type: 1 },
          },
        },
        { fieldName: 'is_out_stock', value: 0, hide: true },
        { fieldName: 'is_stop', value: 0, hide: true },
        { fieldName: 'is_shelves', value: 1, hide: true },
      ],
      table: [
        { name: '名称', prop: 'title', width: '200px' },
        { name: '型号', prop: 'model' },
        { name: '品牌', prop: 'brand_name' },
        { name: '操作', prop: 'htmlButton' },
      ],
    };
  },
  methods: {
    init() {
      this.$refs.lzFormDialog.dialogVisible = true;
      if (this.info && this.info.price_title_id) {
        this.config.url = `/goods/list?price_id=${this.info.price_title_id}`;
      }
      if (this.$route.name === 'order-shipping') {
        this.config.url += '?is_shipping=1';
        this.$set(this.table[0], 'func', (ops) => `${ops.memo !== '' ? `<span class="memo">${ops.memo}</span>` : ''}${ops.title}`);
      }
      this.$nextTick(() => {
        this.$refs.lzList.getData();
      });
    },
    handleCommonBtnCLick(type, element) {
      const isLock = this.list.find((r) => r.id === element.id);
      if (isLock) {
        this.$message.error('该产品已存在, 请勿重复添加');
        return;
      }
      const params = {
        id: element.id,
        title: element.title,
        thumb: element.thumb,
        price_title: {
          price: element.price_title_price,
          id: element.price_title_id,
          name: element.price_title_name,
        },
        spec: element.spec || [],
      };
      this.goods.push(params);
      this.$refs.lzFormDialog.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .memo {
  background: rgb(200, 21, 32);
  color: #fff;
  padding: 0 5px;
  display: inline-block;
  margin-right: 5px;
}
</style>
