export default [
  {
    path: 'code-list',
    component: () => import(/* webpackChunkName: "code-list" */ '@/views/code/index.vue'),
    name: 'code-list',
    meta: { title: '兑换码管理', roles: ['code-list', 'code-recharge'], clearSearch: true, deleteAll: true },
  },
  {
    path: 'ticket-type',
    component: () => import(/* webpackChunkName: "ticket-type" */ '@/views/ticket-type/index.vue'),
    name: 'ticket-type',
    meta: { title: '兑换码类型', roles: ['ticket-type'] },
  },
  {
    path: 'ticket-line',
    component: () => import(/* webpackChunkName: "ticket-line" */ '@/views/ticket-line/index.vue'),
    name: 'ticket-line',
    meta: { title: '产品线管理', roles: ['ticket-line'] },
  },
  {
    path: 'ticket-use-type',
    component: () => import(/* webpackChunkName: "ticket-ues-type" */ '@/views/ticket-use-type/index.vue'),
    name: 'ticket-use-type',
    meta: { title: '出库类型', roles: ['ticket-use-type'] },
  },
];
