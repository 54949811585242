import Cookies from 'js-cookie';
import config from '@/config';

export function getAccessToken() {
  return Cookies.get(config.tokenName);
}

export function setAccessToken(token, ops) {
  return Cookies.set(config.tokenName, token, ops);
}

export function removeAccessToken() {
  return Cookies.remove(config.tokenName);
}
