<template>
  <div>
    <el-menu
      :collapse="isCollapse"
      class="el-menu-vertical"
      :default-active="activeMenu"
      background-color="#000"
      text-color="#fff"
      :unique-opened="true"
      @select="handleSelect"
      active-text-color="#fff">
      <sidebar-item
        v-for="(route, index) in router"
        :key="route.path + index"
        :item="route"
        :level="1"
        :base-path="route.path">
      </sidebar-item>
    </el-menu>
  </div>
</template>
<script>
import sidebarItem from './sidebarItem.vue';

export default {
  name: 'asideBar',
  components: {
    sidebarItem,
  },
  computed: {
    isCollapse() {
      return this.$store.state.app.sidebar === 'mini';
    },
    router() {
      return this.$store.state.user.router;
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  methods: {
    handleSelect(index) {
      this.$router.push({
        path: index,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-menu-vertical {
  border-right: 0;
}
::v-deep .level_menu_2 > .el-menu-item,
::v-deep .level_menu_2 .el-submenu__title {
  padding-left: 50px !important;
}
</style>
