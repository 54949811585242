import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import NProgress from 'nprogress';
import { Notification, Message } from 'element-ui';
import router from '@/router';
import cfg from '@/config';
import { getAccessToken, removeAccessToken } from '@/utils/auth';

function loginOut() {
  removeAccessToken();
  router.push({
    name: 'login',
    query: {
      callback: router.history.current.fullPath,
    },
  });
}

let loadingInstance;

const instance = axios.create({
  baseURL: cfg.api, // eslint-disable-line
  timeout: 0,
});

instance.defaults.transformRequest = [(data, header) => {
  if (!header['Content-Type']) {
    return qs.stringify(data);
  }
  return data;
}];

instance.interceptors.request.use((config) => {
  NProgress.start();
  const token = getAccessToken();
  if (token) config.headers.token = token; // eslint-disable-line
  config.headers['X-Requested-With'] = 'XMLHttpRequest'; // eslint-disable-line
  if (!config.noLoading) loadingInstance = Vue.prototype.$baseLoading();
  return config;
}, (error) => Promise.reject(error));

instance.interceptors.response.use((response) => {
  NProgress.done();
  if (loadingInstance) loadingInstance.close();
  if (response.data.ret === 401) {
    loginOut();
    return null;
  }
  if (response.data.ret !== 0) {
    Message({
      type: 'error',
      message: response.data.msg,
    });
    return null;
  }
  return response.data;
}, (error) => {
  NProgress.done();
  if (!error.response) {
    Notification({
      title: '警告',
      message: error.message,
      type: 'warning',
    });
    return;
  }
  switch (error.response.status) {
    case 504:
      Notification({
        title: '错误提示',
        message: '网络错误，请检查后重试',
        type: 'error',
      });
      break;
    case 401:
      loginOut();
      break;
    case 403:
      Notification({
        title: '错误提示',
        message: '对不起,你没有进行操作的权限',
        type: 'error',
      });
      break;
    default:
      Notification({
        title: '错误提示',
        message: `${error.response.status}错误,请联系管理员`,
        type: 'error',
      });
      break;
  }
});

export default instance;
