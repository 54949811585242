<template>
  <el-form-item
    class="lz-form__item"
    :required="element.required"
    :class="[element.type, `uploda_multiple_${(element.multiple || element.showCard) || 'no'}`]"
    :label="element.name"
    v-if="!element.hide"
    data-flex="cross:center">
    <!-- text -->
    <template v-if="element.type == 'text' || element.type == 'password' || element.type == 'textarea'">
      <el-input
        :clearable="clearable"
        :type="element.type"
        :disabled="elementDisabled"
        @keyup.native.enter="handleKeyupEnter"
        :prefix-icon="element.prefixIcon"
        :autosize="{ minRows: element.minRows || 2, maxRows: element.maxRows || 100}"
        :show-password="element.type == 'password'"
        v-model="element.value"
        :placeholder="element.placeholder || '' + element.name">
      </el-input>
    </template>
    <!-- select -->
    <template v-else-if="element.type == 'select'">
      <el-select
        :clearable="clearable"
        filterable
        :collapse-tags="element.collapseTags || false"
        :multiple="element.multiple || false"
        @change="handleChangeSelect"
        :disabled="elementDisabled"
        :remote="element.remoteMethod != undefined"
        :remote-method="element.remoteMethod"
        v-model="element.value"
        :placeholder="element.placeholder || '' + element.name">
        <el-option
          v-for="item in element.options"
          :key="item.value"
          :disabled="item.disabled"
          :label="item.label"
          :value="String(item.value)">
        </el-option>
      </el-select>
    </template>
    <!-- switch -->
    <template v-else-if="element.type == 'switch'">
      <el-switch
        @change="handleSwitchChange"
        v-model="element.value">
      </el-switch>
    </template>
    <!-- number -->
    <template v-else-if="element.type == 'number'">
      <el-input-number
        controls-position="right"
        :disabled="elementDisabled"
        :min="element.min || 0"
        :max="element.max || 9999999999999999"
        @change="handleChangeInput"
        v-model="element.value"
        :placeholder="element.placeholder || '' + element.name">
      </el-input-number>
    </template>
    <!-- daterange -->
    <template v-else-if="element.type == 'daterange' || element.type == 'datetimerange'">
      <el-date-picker
        filterable
        :disabled="elementDisabled"
        v-model="element.value"
        unlink-panels
        style="width: 100%;"
        :type="element.type"
        :value-format="element.format || 'yyyy-MM-dd'"
        :format="element.format || 'yyyy-MM-dd'"
        range-separator="-"
        :start-placeholder="element.name + '开始日期'"
        :end-placeholder="element.name + '结束日期'"
        :placeholder="'请选择' + element.name"
        :picker-options="element.pickerOptions || {}"
        align="right">
      </el-date-picker>
    </template>
    <!-- cascader -->
    <template v-else-if="element.type === 'cascader'">
      <el-cascader
        clearable
        v-if="element.options.length > 0"
        :disabled="elementDisabled"
        class="w100"
        v-model="element.value"
        :options="element.options"
        :props="{ checkStrictly: element.props.checkStrictly || false, multiple: element.multiple || false }"
        :placeholder="element.placeholder || '' + element.name">
      </el-cascader>
    </template>
    <!-- date -->
    <template v-else-if="element.type == 'date' || element.type =='year'">
      <el-date-picker
        clearable
        :disabled="elementDisabled"
        v-model="element.value"
        :type="element.dateType"
        style="width: 100%;"
        :value-format="element.format || 'yyyy-MM-dd'"
        :placeholder="element.placeholder || '' + element.name">
      </el-date-picker>
    </template>
    <!-- tree select -->
    <template v-else-if="element.type == 'treeSelect'">
      <el-tree-select
        v-if="element.options.length > 0"
        :value="element.value"
        @select-clear="element.value = (typeof element.value != 'object') ? '' : []"
        @check="handleTreeCheck"
        @node-click="handleTreeNodeClick"
        @searchFun="handleTreeFilter"
        :styles="{
          width: '100%'
        }"
        :disabled="elementDisabled"
        ref="treeSelect"
        :selectParams="{
          clearable: true,
          'collapse-tags': element.collapseTags == undefined,
          placeholder: element.placeholder || '请选择' + element.name,
          multiple: element.multiple || false,
        }"
        :treeParams="{
          data: element.options,
          filterable: true,
          'check-strictly': element.checkStrictly || false,
          'default-expand-all': false,
          'highlight-current': typeof element.value != 'object',
          props: element.props,
        }" />
    </template>
    <!-- editor -->
    <template v-else-if="element.type == 'editor'">
      <lz-card :title="element.name" :required="element.required">
        <div v-show="elementDisabled" v-html="element.value"></div>
        <div v-show="!elementDisabled">
          <vue-ueditor-wrap
            v-model="element.value"
            :config="cfg.ueitorConfig">
          </vue-ueditor-wrap>
        </div>
      </lz-card>
    </template>
    <template v-else-if="element.type == 'upload'">
      <template v-if="element.multiple || element.showCard">
        <lz-card :title="element.name" :required="element.required">
          <lz-upload :disabled="elementDisabled" :element="element"></lz-upload>
        </lz-card>
      </template>
      <template v-else>
        <lz-upload :disabled="elementDisabled" :element="element"></lz-upload>
      </template>
     </template>
  </el-form-item>
</template>

<script>
import cfg from '@/config';
import vueUeditorWrap from 'vue-ueditor-wrap';

export default {
  name: 'lzFormItem',
  components: {
    vueUeditorWrap,
  },
  props: {
    element: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cfg,
    };
  },
  computed: {
    clearable() {
      return this.element.clearable || true;
    },
    elementDisabled() {
      return this.element.disabled || this.disabled;
    },
    constant() { // 全局字典
      return this.$store.state.user.constant;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 判断是否下拉且需要调取远程接口
      if ((this.element.type === 'select'
        || this.element.type === 'treeSelect'
        || this.element.type === 'cascader') && this.element.remote) {
        this.$set(this.element, 'options', []);
        const props = this.element.props;
        const params = Object.assign({
          page_size: 1000,
        }, props.params || {});
        const disabledValue = this.element.disabledValue || [];
        this.$baseHttp.get(props.url, { params, noLoading: true }).then((response) => {
          if (!response) return;
          const data = response.data.data || response.data;
          this.$set(this.element, 'options', data.map((r) => ({
            ...r,
            label: this.getLabel(r, props.label),
            value: r[props.value],
            disabled: disabledValue.includes(String(r[props.value])),
          })));
          // 是否显示顶级
          if (props.isTop) {
            const topData = {
              children: [...this.element.options],
            };
            topData[props.label] = '顶级';
            topData[props.value] = 0;
            this.$set(this.element, 'options', [topData]);
          }
          this.$nextTick(() => {
            if (this.element.type === 'treeSelect' && typeof this.element.value === 'object') this.$refs.treeSelect._treeNodeClickFun = () => {}; // eslint-disable-line
            if (!['admin-user-rule', 'company-role-rule'].includes(this.$route.name) && this.element.type === 'treeSelect' && typeof this.element.value !== 'object' && !this.element.clickTreeTop) {
              this.handleGetTree(this.element.options);
            }
          });
        });
      }
      if (this.element.constantDict) {
        this.$set(this.element, 'options', this.constant[this.element.constantDict]);
      }
    },
    handleGetTree(list) {
      list.forEach((item) => {
        if (item.children !== undefined && item.children.length > 0) {
          this.$set(item, 'disabled', true);
          this.handleGetTree(item.children);
        }
      });
    },
    handleKeyupEnter() {
      this.$emit('keyup-enter');
    },
    handleChangeSelect() {
      this.$emit('change-select', this.element);
    },
    handleSwitchChange() {
      this.$emit('switch-change', this.element);
    },
    handleTreeCheck(data, node) {
      this.$set(this.element, 'value', node.checkedKeys);
    },
    handleTreeFilter(val) {
      this.$nextTick(() => {
        this.$refs.treeSelect.filterFun(val);
      });
    },
    handleTreeNodeClick(data, node) {
      const props = this.element.props;
      // 如果是禁用 只能选择一个
      if (node.data.disabled) {
        this.$set(this.$refs.treeSelect, 'labels', '');
        this.$set(this.$refs.treeSelect, 'ids', []);
        this.$set(this.$refs.treeSelect, 'visible', true);
        return;
      }
      this.$set(this.element, 'value', node.data[props.value]);
      this.$emit('tree-node-click', data, this.element);
    },
    handleChangeInput() {
      this.$emit('change-input', this.element);
    },
    getLabel(ele, key) {
      if (key.indexOf('||') === -1) {
        return ele[key];
      }
      const str = [];
      key.split('||').forEach((r) => {
        str.push(ele[r]);
      });
      return str.join('-').toString();
    },
  },
};
</script>
<style lang="scss" scoped>
.lz-form__item {
  ::v-deep .el-form-item__label {
    line-height: 25px;
  }
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
    flex: 1;
    margin-right: 10px;
    .el-select,
    .el-input-number {
      width: 100%;
    }
  }
}
</style>
