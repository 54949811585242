<template>
  <el-breadcrumb data-flex-box="1" class="app-breadcrumb" separator-class="el-icon-arrow-right">
    <transition-group name="breadcrumb">
      <template v-for="(item,index) in levelList">
        <el-breadcrumb-item
          :key="item.path"
          v-if='item.meta.title'>
          <span
            v-if="item.redirect==='noRedirect' || index == levelList.length - 1"
            class="no-redirect">
            {{ item.meta.title }}
          </span>
          <router-link
            v-else
            :to="item.redirect || item.path">
            {{ item.meta.title }}
          </router-link>
        </el-breadcrumb-item>
      </template>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'breadcrumb',
  data() {
    return {
      levelList: null,
    };
  },
  created() {
    this.getBreadcrumb();
  },
  watch: {
    $route: 'getBreadcrumb',
  },
  methods: {
    getBreadcrumb() {
      let { matched } = this.$route;
      const first = matched[0];
      if (first && first.name !== 'index-layout') {
        matched = [
          {
            path: '/index', meta: { title: '首页' },
          },
        ].concat(matched);
        this.levelList = matched;
        return;
      }
      this.levelList = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  font-size: 14px;
  margin-left: 20px;
  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
