export default [
  {
    path: 'article-class',
    component: () => import(/* webpackChunkName: "category" */ '@/views/frontend/category/index.vue'),
    name: 'article-class',
    meta: { title: '分类管理', roles: ['article-class'] },
  },
  {
    path: 'site-article',
    component: () => import(/* webpackChunkName: "article" */ '@/views/frontend/article/index.vue'),
    name: 'site-article',
    meta: { title: '文章管理', roles: ['site-article'] },
  },
  {
    path: 'site-banner',
    component: () => import(/* webpackChunkName: "banner" */ '@/views/frontend/banner/index.vue'),
    name: 'site-banner',
    meta: { title: '首页banner', roles: ['site-banner'] },
  },
  {
    path: 'particle-banner',
    component: () => import(/* webpackChunkName: "particle-banner" */ '@/views/frontend/effect/index.vue'),
    name: 'particle-banner',
    meta: { title: '首页banner', roles: ['particle-banner'] },
  },
  {
    path: 'site-link',
    component: () => import(/* webpackChunkName: "link" */ '@/views/frontend/link/index.vue'),
    name: 'site-link',
    meta: { title: '合作企业', roles: ['site-link'] },
  },
  {
    path: 'site-help',
    component: () => import(/* webpackChunkName: "help" */ '@/views/frontend/help/index.vue'),
    name: 'site-help',
    meta: { title: '帮助文档', roles: ['site-help'] },
  },
];
