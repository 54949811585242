import Vue from 'vue';
import router from '@/router';
import { Message } from 'element-ui';

/**
 * @name: 列表格式化
 * @param {url} {String} 请求地址
 * @param {search} {Array} 搜索数组
 * @param {isTree} {Boolean} 是否层级树
 */

export function getList(url, search, isTree = false, searchFomate) {
  let params = {};
  search.forEach((element) => {
    params[element.fieldName] = element.value;
    if (Array.isArray(element.value)) {
      if (element.fieldName.indexOf('||') === -1) {
        params[element.fieldName] = element.value.toString();
      } else {
        const names = element.fieldName.split('||');
        names.forEach((key, index) => {
          params[key] = element.value[index] || '';
        });
        delete params[element.fieldName];
      }
    }
  });
  if (params.page_size === 0) params.page_size = 20;
  delete params.page_total;
  if (searchFomate) params = searchFomate(params);
  return new Promise((resolve) => {
    Vue.prototype.$baseHttp.get(url, { params, noLoading: true }).then((response) => {
      if (response.ret !== 0) {
        Message({
          type: 'error',
          message: response.msg,
        });
        resolve(null);
      }
      if (!isTree) {
        search.filter((r) => r.fieldName === 'page_size' || r.fieldName === 'page_total').forEach((el) => {
          if (el.fieldName === 'page_size') {
            Vue.set(el, 'value', Number(response.data.per_page));
          } else {
            // 售后兑换显示total2
            Vue.set(el, 'value', Number(response.data.total));
          }
        });
        resolve(response.data);
      }
      resolve(response);
    });
  });
}

/**
 * @name: 搜索参数格式化
 * @param {search} {Array} 搜索数组
 */
export function getSearchValue(search) {
  const query = {};
  search.forEach((element) => {
    if (element.noRouter) return;
    query[element.fieldName] = element.value;
    if (Array.isArray(element.value)) {
      if (element.fieldName.indexOf('||') === -1) {
        query[element.fieldName] = element.value.toString();
      } else {
        const names = element.fieldName.split('||');
        names.forEach((key, index) => {
          query[key] = element.value[index] || '';
        });
        delete query[element.fieldName];
      }
    }
  });
  return query;
}

export function clearSearchValue(search) {
  search.forEach((element) => {
    if (element.noRouter || element.fieldName === 'page_size' || element.noClear) return;
    const valueType = typeof element.value;
    Vue.set(element, 'value', valueType === 'object' ? [] : '');
  });
  return getSearchValue(search);
}

/**
 * @name: 映射路由参数
 * @param {search} {Array} 搜索数
 */
export function setSearchValue(search) {
  const query = router.history.current.query;
  Object.keys(query).forEach((key) => {
    const element = search.find((r) => r.fieldName === key);
    if (!element) {
      // 判断是否存在||
      const inRouter = search.find((r) => r.fieldName.split('||')[0] === key);
      if (inRouter) {
        const names = inRouter.fieldName.split('||');
        const dageReg = /^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})|(\d+)-(\d{1,2})-(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/;
        names.forEach((r) => {
          if (query[r]) inRouter.value.push(dageReg.test(query[r]) ? String(query[r]) : Number(query[r]));
        });
      }
      return;
    }
    Vue.set(element, 'value', query[key]);
    if (Array.isArray(element.value) || element.multiple) Vue.set(element, 'value', query[key] ? [query[key].toString()] : []);
  });
}
