export default [
  {
    path: 'admin-user-list',
    component: () => import(/* webpackChunkName: "admin-user-list" */ '@/views/user-list/index.vue'),
    name: 'admin-user-list',
    meta: { title: '用户列表', roles: ['admin-user-list'] },
  },
  {
    path: 'admin-user-group',
    component: () => import(/* webpackChunkName: "admin-user-group" */ '@/views/user-group/index.vue'),
    name: 'admin-user-group',
    meta: { title: '角色列表', roles: ['admin-user-group'] },
  },
  {
    path: 'admin-user-rule',
    component: () => import(/* webpackChunkName: "admin-user-rule" */ '@/views/user-rule/index.vue'),
    name: 'admin-user-rule',
    meta: { title: '权限规则', roles: ['admin-user-rule'] },
  },
  {
    path: 'admin-user-rule-article',
    component: () => import(/* webpackChunkName: "admin-user-rule-article" */ '@/views/user-rule-article/index.vue'),
    name: 'admin-user-rule-article',
    meta: { title: '手册内容', roles: ['admin-user-rule-article'] },
  },
  {
    path: 'admin-user-rule-article',
    component: () => import(/* webpackChunkName: "admin-user-rule-article-view" */ '@/views/user-rule-article/detail.vue'),
    name: 'admin-user-rule-article',
    meta: { title: '手册内容', roles: ['admin-user-rule-article'], type: 'view' },
  },
];
