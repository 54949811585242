<template>
  <div data-flex="cross:center" class="header">
    <i
      class="unfold"
      @click="handleClickFold"
      :class="sidebar == '' ? 'ri-menu-fold-line' : 'ri-menu-unfold-line'" >
    </i>
    <breadcrumb></breadcrumb>
    <div class="icons">
      <template v-for="element in menus">
        <el-tooltip :key="element.icon" effect="dark" :content="element.title" placement="bottom">
          <i :class="element.icon" :key="element.icon" @click="handleMenuClick(element)"></i>
        </el-tooltip>
      </template>
    </div>
    <el-dropdown  size="small">
      <div data-flex="cross:center" class="user-info">
        <el-avatar src="https://i.gtimg.cn/club/item/face/img/2/16022_100.gif"></el-avatar>
        <span class="username">{{ info.user_name }}</span>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item icon="ri-user-3-line" @click.native="handleUpdateUser">修改资料</el-dropdown-item>
        <el-dropdown-item icon="ri-logout-circle-r-line" @click.native="handleLogout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <menu-search ref="menuSearch"></menu-search>
    <lz-dialog
      :width="500"
      :dialogBtns="[{ key: 'save', name: '保存', type: 'primary' },]"
      @dialog-btn-click="handleCommonBtnCLick"
      title="个人资料"
      ref="lzDialog">
      <lz-form :form="form" :col="1" ref="lzForm"></lz-form>
    </lz-dialog>
  </div>
</template>
<script>
import cfg from '@/config';
import screenfull from 'screenfull';

const sex = [
  { label: '男', value: 0 },
  { label: '女', value: 1 },
];

export default {
  name: 'headBar',
  data() {
    return {
      menus: [
        { icon: 'ri-search-line', type: 'search', title: '搜索菜单' },
        { icon: 'ri-fullscreen-fill', type: 'fullscreen', title: '全屏' },
        { icon: 'ri-gift-line', type: 'gift', title: '兑换' },
        { icon: 'ri-slideshow-3-line', type: 'desktop', title: '前台' },
        { icon: 'ri-book-line', type: 'doc', title: '帮助文档' },
      ],
      screenfull: false,
      form: [
        { fieldName: 'nick_name', type: 'text', name: '姓名', value: '', required: true },
        { fieldName: 'user_name', type: 'text', name: '帐号名称', value: '', required: true, disabled: true },
        { fieldName: 'gender', type: 'select', name: '性别', value: '', required: false, options: sex },
        { fieldName: 'password', placeholder: '不修改请放空', type: 'password', name: '密码', value: '', required: false },
        { fieldName: 'password2', placeholder: '不修改请放空', type: 'password', name: '确认密码', value: '', required: false },
        { fieldName: 'email', type: 'text', name: '邮箱', value: '', required: false },
        { fieldName: 'phone', type: 'text', name: '手机', value: '', required: false },
        { fieldName: 'id', type: 'text', name: 'id', value: '', required: false, hide: true },
      ],
    };
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    info() {
      return this.$store.state.user.info;
    },
  },
  methods: {
    handleClickFold() {
      this.$store.dispatch('app/setChangeSideBar', this.sidebar === '' ? 'mini' : '');
    },
    handleMenuClick(element) {
      switch (element.type) {
        case 'search':
          this.$refs.menuSearch.dialogVisible = true;
          break;
        case 'fullscreen':
          if (!screenfull.isEnabled) {
            this.$baseAlert({
              content: '当前浏览器不支持全屏模式',
            });
            return;
          }
          this.screenfull = !this.screenfull;
          this.$set(element, 'icon', this.screenfull ? 'ri-fullscreen-exit-line' : 'ri-fullscreen-fill');
          screenfull.toggle();
          break;
        case 'gift':
          window.open(cfg.giftUrl);
          break;
        case 'desktop':
          window.open('//www.liyi1.com.cn');
          break;
        case 'doc':
          window.open(`${window.location.origin}/doc`);
          break;
        default:
          break;
      }
    },
    handleUpdateUser() {
      const response = this.$baseDeepClone(this.info);
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: response,
        });
      });
    },
    async handleCommonBtnCLick() {
      const params = this.$refs.lzForm.getValue();
      if (!params) return;
      if (params.password !== params.password2) {
        this.$message.error('两次密码不一致');
        return;
      }
      if (params.password === '') {
        delete params.password;
        delete params.password2;
      }
      const response = await this.$baseHttp.post('/admin/save', params);
      if (!response) return;
      this.$refs.lzDialog.dialogVisible = false;
      if (params.password === undefined) {
        this.$message.success('操作成功');
        Object.keys(params).forEach((key) => {
          this.info[key] = params[key];
        });
        return;
      }
      this.$baseAlert({
        content: '修改成功,请重新登录',
        callback: () => {
          this.$store.dispatch('user/setLogout').then(() => {
            this.$router.push({ name: 'login' });
            setTimeout(() => {
              window.location.reload();
            }, 10);
          });
        },
      });
    },
    handleLogout() {
      this.$baseConfirm({
        content: '确定退出？',
        callConfirm: () => {
          this.$store.dispatch('user/setLogout').then(() => {
            this.$router.push({ name: 'login' });
            setTimeout(() => {
              window.location.reload();
            }, 10);
          });
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  padding: 0 20px;
}
.unfold {
  cursor: pointer;
  font-size: 16px;
  color: rgba(0, 0, 0, .65);
}
.icons {
  margin-right: 20px;
  i {
    cursor: pointer;
    margin: 0 6px;
    font-size: 16px;
    color: rgba(0, 0, 0, .65);
  }
}
.username {
  margin-left: 10px;
}

.user-info {
  cursor: pointer;
}
</style>
