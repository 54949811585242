<template>
  <el-dialog
    custom-class="search-menu-dialog"
    width="40%"
    :visible.sync="dialogVisible">
    <el-autocomplete
      size="medium"
      ref="searchInput"
      :fetch-suggestions="querySearchAsync"
      placeholder="请输入菜单"
      prefix-icon="el-icon-search"
      @select="handleSelect"
      v-model="keyword">
    </el-autocomplete>
  </el-dialog>
</template>
<script>
import Fuse from 'fuse.js';
import path from 'path';

export default {
  name: 'menuSearch',
  data() {
    return {
      dialogVisible: false,
      keyword: '',
      searchPool: [],
    };
  },
  computed: {
    router() {
      return this.$store.state.user.router;
    },
  },
  watch: {
    searchPool(list) {
      this.initFuse(list);
    },
    dialogVisible: function change(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
        return;
      }
      this.keyword = '';
    },
  },
  mounted() {
    this.searchPool = this.generateRoutes(this.router);
  },
  methods: {
    initFuse(list) {
      this.fuse = new Fuse(list, {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [{
          name: 'title',
          weight: 0.7,
        }, {
          name: 'path',
          weight: 0.3,
        }],
      });
    },
    generateRoutes(routes, basePath = '/', prefixTitle = []) {
      let res = [];
      for (const router of routes) { // eslint-disable-line
        if (router.meta && router.meta.is_menu === false) { continue; } // eslint-disable-line
        const data = {
          path: path.resolve(basePath, router.path),
          title: [...prefixTitle],
        };
        if (router.meta && router.meta.title && !router.hidden) {
          data.title = [...data.title, router.meta.title];
          if (router.redirect !== 'noRedirect' && router.path !== 'index') {
            res.push(data);
          }
        }
        if (router.children) {
          const tempRoutes = this.generateRoutes(router.children, data.path, data.title);
          if (tempRoutes && tempRoutes.length >= 1) {
            res = [...res, ...tempRoutes];
          }
        }
      }
      return res;
    },
    querySearchAsync(query, cb) {
      const results = this.fuse.search(query);
      cb(results.map((r) => ({
        path: r.item.path,
        value: r.item.title.join(' > '),
      })));
    },
    handleSelect(element) {
      this.$router.push(element.path);
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .search-menu-dialog {
  background: none;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-autocomplete {
    width: 100%;
  }
  .el-input__inner {
    height: 60px;
    line-height: 60px;
    border: 0;
    padding-left: 40px;
  }
}
</style>
