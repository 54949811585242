import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/normalize.scss';
import './assets/css/data-flex.css';
import './assets/css/remixicon.css';
import './assets/css/style.scss';
import './assets/css/transition.scss';
import './plugins';

Vue.config.productionTip = false;

document.title = window.location.hostname === 'backend.liyi1.com.cn' ? '礼一科技管理系统' : '测试管理系统';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
