<template>
  <el-popover
    placement="top-start"
    :key="index"
    width="200"
    trigger="hover">
  <p>价位：{{ element.price_title.name }}</p>
  <p>金额：{{ element.price_title.price }}元</p>
  <div
    class="lz-upload"
    slot="reference"
    :class="{
      disabled: element.is_global !== undefined,
      checked: list.some(v => v.id === element.id)
    }">
      <img :src="element.thumb" />
      <div class="title">{{ element.title }}</div>
      <div v-if="element.memo" class="memo">{{ element.memo }}</div>
      <div class="action" v-if="element.is_global === undefined">
        <i
          @click.stop="handlePreview(element.id)"
          class="el-icon-view">
        </i>
        <i
          @click.stop="handleCheckProduct(element)"
          class="el-icon-folder">
        </i>
        <i
          v-if="list.some(v => v.id === element.id)"
          class="el-icon-delete"
          @click.stop="handleDelete(element.id)">
        </i>
      </div>
    </div>
  </el-popover>
</template>
<script>
export default {
  props: {
    element: {
      defualt: {},
    },
    index: {
      default: 0,
    },
    list: {
      default: () => [],
    },
  },
  methods: {
    handleCheckProduct(element) {
      this.$emit('click-product', element);
    },
    handleDelete(id) {
      const index = this.list.findIndex((r) => r.id === id);
      if (index === -1) return;
      this.list.splice(index, 1);
    },
    handlePreview(id) {
      this.$emit('preview-product', id);
    },
  },
};
</script>
