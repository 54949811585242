<template>
  <div data-flex="cross:center main:center" data-flex-box="1">
    <el-dropdown @visible-change="handleVisibleMenu">
      <span class="more">
        更多<i class="el-icon-arrow-down el-icon--right" :class="{ active: show }"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item icon="ri-close-line" @click.native="handleClickMenu('other')">关闭其他</el-dropdown-item>
        <el-dropdown-item icon="ri-arrow-left-line" @click.native="handleClickMenu('left')">关闭左侧</el-dropdown-item>
        <el-dropdown-item icon="ri-arrow-right-line" @click.native="handleClickMenu('right')">关闭右侧</el-dropdown-item>
        <el-dropdown-item icon="ri-scan-line" @click.native="handleClickMenu('all')">关闭所有</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  name: 'tabsMenu',
  data() {
    return {
      show: false,
    };
  },
  methods: {
    handleVisibleMenu(val) {
      this.show = val;
    },
    handleClickMenu(type) {
      this.$store.dispatch('app/setCloseTags', {
        type,
        value: this.$route,
      });
      if (type === 'all') this.$emit('update:value', '/index');
    },
  },
};
</script>

<style lang="scss" scoped>
.more {
  cursor: pointer;
  i {
    transition: all .3s;
    &.active {
      transform: rotate(180deg);
    }
  }
}
</style>
