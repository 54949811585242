<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#app {
  height: 100vh;
  background-color: #f6f8f9;
}
</style>
