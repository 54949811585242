export default [
  {
    path: 'agent-industry',
    component: () => import(/* webpackChunkName: "agent-industry" */ '@/views/agent/industry/index.vue'),
    name: 'agent-industry',
    meta: { title: '代理商行业', roles: ['agent-attribute-industry'] },
  },
  {
    path: 'agent-list',
    component: () => import(/* webpackChunkName: "agent-list" */ '@/views/agent/index.vue'),
    name: 'agent-list',
    meta: { title: '分销商', roles: ['agent-list'] },
  },
];
