const isDev = window.location.hostname === 'backend.liyi1.com.cn';
const api = isDev ? 'http://api.liyi1.com.cn' : 'http://api-test.liyi1.com.cn';
const giftUrl = isDev ? 'https://gift.liyi1.com.cn' : 'https://gift-test.liyi1.com.cn';

export default {
  // api地址
  api,
  // 前台地址
  giftUrl,
  // token名称
  tokenName: 'lzAccessToken',
  // 编辑器配置
  ueitorConfig: {
    autoHeightEnabled: false,
    serverUrl: `${api}/backend_editor/controller.php`,
    initialFrameHeight: 300,
    initialFrameWidth: '100%',
    UEDITOR_HOME_URL: 'http://backend.liyi1.com.cn/UEditor/',
    zIndex: 100,
  },
};
