<template>
  <el-form
    class="lz-search-container"
    @submit.native.prevent
    data-flex="cross:center">
    <template v-if="slideSearch">
      <template v-for="(element, index) in search">
        <lz-form-item
          v-if="element.type"
          :style="{ width: element.width || '150px' }"
          :key="index"
          @keyup-enter="handleSearch"
          :element="element">
        </lz-form-item>
      </template>
      <el-form-item v-if="search.some(v => v.type) && showSearch" class="mr-10">
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form-item>
      <el-form-item v-if="isRouter && $route.meta.clearSearch" class="mr-10">
        <el-button type="primary" @click="handleClearSearch">重置搜索</el-button>
      </el-form-item>
    </template>
    <el-form-item v-if="isRouter && $route.meta.slideSearch" class="mr-10">
      <el-button type="primary" @click="slideSearch = !slideSearch">{{ slideSearch ? '隐藏筛选' : '显示筛选' }}</el-button>
    </el-form-item>
    <template v-if="isRouter">
      <template v-if="$route.name != 'order-list' && $route.name != 'code-list' && $route.name != 'code-recharge' && $route.name != 'order-shipping'">
        <template v-for="(index, key) in $route.meta.roleBtn" >
          <template v-if="btn[key]">
            <el-form-item v-if="$route.meta.roleBtn[key] && !['record_list', 'delete'].includes(key)" :key="key" class="mr-10">
              <el-button type="primary" @click="handleSearchBtnsClick(key)">{{ btn[key] }}</el-button>
            </el-form-item>
            <el-form-item v-else-if="$route.meta.roleBtn.delete && $route.meta.deleteAll" :key="key" class="mr-10">
              <el-button type="primary" @click="handleSearchBtnsClick(key)">{{ btn[key] }}</el-button>
            </el-form-item>
          </template>
          <template v-else><span :key="key" style="display: none">{{ key }}</span></template>
        </template>
      </template>
      <template v-else-if="['code-list', 'code-recharge'].includes($route.name)">
         <el-form-item>
          <el-dropdown>
              <el-button type="primary">
                批量操作<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <template v-for="(index, key) in $route.meta.roleBtn">
                  <el-dropdown-item
                    v-if="$route.meta.roleBtn[key]"
                    @click.native="handleSearchBtnsClick(key)"
                    :key="key">
                    {{ btn[key] }}
                  </el-dropdown-item>
                </template>
              </el-dropdown-menu>
            </el-dropdown>
         </el-form-item>
      </template>
      <template v-else>
        <el-form-item v-if="$route.meta.roleBtn.add && $route.name != 'order-shipping'" class="mr-10">
          <el-button type="danger" @click="handleSearchBtnsClick('add')">人工兑换</el-button>
        </el-form-item>
        <el-form-item>
          <el-dropdown>
            <el-button type="primary">
              批量操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <template v-for="(index, key) in $route.meta.roleBtn">
                <el-dropdown-item
                  v-if="!['add', 'delete'].includes(key) && $route.meta.roleBtn[key]"
                  @click.native="handleSearchBtnsClick(key)"
                  :key="key">
                  {{ btn[key] }}
                </el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </template>
    </template>
    <!-- 自定义按钮 -->
    <template v-if="searchBtn.length > 0">
      <el-form-item v-for="(item) in searchBtn" :key="item.name" class="mr-10">
        <el-button :type="item.type" @click="handleSearchBtnsClick(item.key)">{{ item.name }}</el-button>
      </el-form-item>
    </template>
    <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="handleFileChange" ref="file" type="file" name="file" class="el-upload__input">
  </el-form>
</template>
<script>
import { getSearchValue, clearSearchValue } from '@/utils/search';

export default {
  name: 'lz-search',
  props: {
    search: {
      type: Array,
      default: () => [],
    },
    searchBtn: {
      type: Array,
      default: () => [],
    },
    showSearch: {
      default: true,
    },
    // 判断是否在路由显示分页信息
    isRouter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      slideSearch: true,
      type: '',
      btn: {
        add: '新增',
        move: '批量转移',
        delete: '批量删除',
        cancel: '批量作废',
        return: '批量退换',
        import: '导入',
        export: '导出',
        sms: '发短信',
        end: '完结',
        audit: '审核',
        ship: '发货',
        checkout: '结账',
        assign: '批量撤销分配',
        recharge: '批量充值',
        ship_date_ready: '预发货日期',
        import_person: '批量导入联系人/联系方式',
        'import-qrcode': '批量码',
        'set-assign': '批量分配',
      },
    };
  },
  mounted() {
    if (this.isRouter && this.$route.meta.slideSearch) {
      this.slideSearch = false;
    }
  },
  methods: {
    handleSearch() {
      const page = this.search.find((r) => r.fieldName === 'page');
      if (page) this.$set(page, 'value', 1);
      // 如果是路由跳转修改地址栏参数
      if (this.isRouter) {
        const query = getSearchValue(this.search);
        query.page = 1;
        this.$router.push({ query });
      }
      this.$emit('change-url');
    },
    handleClearSearch() {
      const query = clearSearchValue(this.search);
      query.page = 1;
      this.$router.push({ query });
      this.$emit('change-url');
    },
    handleFileChange(e) {
      if (e.target.value === '') return;
      const file = e.target.files;
      const form = new FormData();
      Object.keys(file).forEach((v) => {
        form.append('files[]', file[v]);
      });
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 0,
      };
      this.$baseHttp.post('/attachment/upload', form, config).then((response) => {
        if (!response) return;
        this.$refs.file.setAttribute('type', 'text');
        this.$refs.file.setAttribute('type', 'file');
        this.$set(response, 'type', this.type);
        this.$emit('upload-success', response);
      });
    },
    /**
     * 菜单返回按钮点击
     * @param {type} {String} 类型
    */
    handleSearchBtnsClick(type) {
      this.type = type;
      if (type === 'import' || type === 'import_person') {
        this.$refs.file.click();
        return;
      }
      this.$emit('search-btn-click', type);
    },
  },
};
</script>

<style lang="scss" scoped>
.lz-search-container {
  flex-wrap: wrap;
  ::v-deep .lz-form__item {
    .el-form-item__label {
      display: none;
    }
  }
}
</style>
