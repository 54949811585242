export default [
  {
    path: 'company',
    component: () => import(/* webpackChunkName: "company" */'@/views/company/index.vue'),
    name: 'company',
    meta: { title: '客户管理', roles: ['company-list'], clearSearch: true },
  },
  {
    path: 'company-not-deal',
    component: () => import(/* webpackChunkName: "company-not-deal" */'@/views/company/index.vue'),
    name: 'company-not-deal',
    meta: { title: '未成交客户管理', roles: ['company-not-deal'], clearSearch: true },
  },
  {
    path: 'type',
    component: () => import(/* webpackChunkName: "type" */'@/views/type/index.vue'),
    name: 'type',
    meta: { title: '企业类型', roles: ['company-type'] },
  },
  {
    path: 'industry',
    component: () => import(/* webpackChunkName: "industry" */'@/views/industry/index.vue'),
    name: 'industry',
    meta: { title: '行业', roles: ['company-industry'] },
  },
  {
    path: 'size',
    component: () => import(/* webpackChunkName: "size" */'@/views/size/index.vue'),
    name: 'size',
    meta: { title: '企业规模', roles: ['company-size'] },
  },
  {
    path: 'company-role-group',
    component: () => import(/* webpackChunkName: "company-role-group" */'@/views/company/group/index.vue'),
    name: 'company-role-group',
    meta: { title: '客户角色', roles: ['company-role-group'] },
  },
  {
    path: 'company-role-rule',
    component: () => import(/* webpackChunkName: "company-role-rule" */'@/views/company/rule/index.vue'),
    name: 'company-role-rule',
    meta: { title: '客户角色', roles: ['company-role-rule'] },
  },
];
