<template>
  <el-table
    :data="list"
    v-if="!refreshTable"
    v-loading="loading"
    :height="tableHeight"
    width="100%"
    @selection-change="handleCheckboxChange"
    :default-expand-all="defaultExpandAll"
    :row-key="rowKey"
    :header-cell-style="{backgroundColor: '#f6f8fa'}"
    border>
    <el-table-column type="selection" width="55" :selectable='isSelectionDisabled' v-if="selection"></el-table-column>
    <el-table-column width="80" type="index" v-if="isTree"></el-table-column>
    <template v-for="(element, index) in table">
      <template v-if="element.prop != 'htmlButton'">
        <!-- expand -->
        <el-table-column
          :key="index"
          type="expand"
          v-if="element.type == 'expand'">
          <template slot-scope="{ row }">
            <div v-html="element.func(row)"></div>
          </template>
        </el-table-column>
        <!-- 自定义函数 -->
        <el-table-column
          v-else-if="element.func"
          :key="index"
          :prop="element.prop"
          :align="element.align"
          :width="element.width || ''"
          :min-width="element.minWidth || ''"
          :label="element.name">
          <template slot-scope="{ row }">
            <div style="white-space: pre-line;" v-html="element.func(row)"></div>
          </template>
        </el-table-column>
        <!-- switch -->
        <el-table-column
          v-else-if="element.type == 'switch'"
          :key="index"
          :prop="element.prop"
          :align="element.align"
          :width="element.width || ''"
          :label="element.name">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row[element.prop]"
              :active-value="element.activeVlue == undefined ? 1 : element.activeVlue"
              :disabled="element.switchDisabled ? element.switchDisabled(row) : false"
              @change="element.switchChange(row)"
              :inactive-value="element.inactiveValue == undefined ? 0 : element.inactiveValue">
            </el-switch>
          </template>
        </el-table-column>
        <!-- 图片 -->
        <el-table-column
          v-else-if="element.type == 'image'"
          :key="index"
          :align="element.align"
          :width="element.width || ''"
          :label="element.name">
          <template slot-scope="{ row }">
            <el-image
              v-if="row[element.prop]"
              style="width: 100px; height: 100px"
              :src="row[element.prop]"
              fit="contain">
            </el-image>
          </template>
        </el-table-column>
        <!-- 可编辑 -->
        <el-table-column
          v-else-if="element.type == 'edit'"
          :key="index"
          :align="element.align"
          :width="element.width || ''"
          :label="element.name">
          <template slot-scope="{ row }">
            <el-input-number
              v-if="element.editType == 'number'"
              :min="element.min ? row[element.min] : 0"
              :max="element.max ? row[element.max] : 99999999999"
              v-model="row[element.prop]">
            </el-input-number>
          </template>
        </el-table-column>
        <!-- 未定义显示prop -->
        <el-table-column
          v-else
          :key="index"
          :align="element.align"
          :width="element.width || ''"
          :prop="element.prop"
          :label="element.name">
        </el-table-column>
      </template>
      <template v-else>
        <el-table-column
          :key="index"
          :align="element.align"
          :label="element.name"
          :fixed="element.fixed || false"
          :width="element.width || '150px'">
          <template slot-scope="scope">
            <el-link
              v-for="(item, index) in scope.row.htmlButton"
              :key="index"
              class="list-button"
              :type="item.type || 'primary'"
              :underline="false"
              :disabled="item.disabled || false"
              style="font-size: 12px;"
              @click="handleTableBtnClick({ type: item.key, scope: scope.row, index: scope.$index })">
              {{ item.name }}
            </el-link>
          </template>
        </el-table-column>
      </template>
    </template>
    <lz-empty slot="empty" :empty-text="emptyText"></lz-empty>
  </el-table>
</template>
<script>
export default {
  name: 'lzTableList',
  watch: {
    defaultExpandAll: function change() {
      this.refreshTable = true;
      setTimeout(() => {
        this.refreshTable = false;
      });
    },
  },
  props: {
    table: {
      default: () => [],
      type: Array,
    },
    tableHeight: {
      default: null,
    },
    list: {
      default: () => [],
      type: Array,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    emptyText: {
      default: '暂无数据',
      type: String,
    },
    isTree: { // 是否层级树
      default: false,
      type: Boolean,
    },
    defaultExpandAll: {
      default: true,
      type: Boolean,
    },
    rowKey: {
      default: 'id',
      type: String,
    },
    selection: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      refreshTable: false,
    };
  },
  methods: {
    isSelectionDisabled(row) {
      return row.selectionNoDisabled === undefined ? true : row.selectionNoDisabled;
    },
    /**
     * 表格操作栏按钮点击
     * @param {type} {String} 类型
     * @param {scope} {Object} 数据对象
    */
    handleTableBtnClick({ type, scope, index }) {
      this.$emit('table-btn-click', type, scope, index);
    },
    /**
     * 表格多选改变事件
     * @param {scope} {value} 数据对象
    */
    handleCheckboxChange(value) {
      this.$emit('table-checkbox-change', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.list-button {
  margin-right: 10px;
}
</style>
