<template>
  <el-card shadow="never">
    <div slot="header" class="lz-card__header" data-flex="main:justify cross:center">
      <div><span class="required" v-if="required">*</span>{{ title }}</div>
      <div class="lz-card__right">
        <slot name="right" />
      </div>
    </div>
    <slot />
  </el-card>
</template>
<script>
export default {
  name: 'lzCard',
  props: {
    title: {
      type: String,
      default: '标题',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.el-card {
  margin-bottom: 20px;
  ::v-deep .el-card__header {
    background-color: #f6f8fa;
    padding: 10px 20px;
    .lz-card__header {
      min-height: 25px;
      font-size: 14px;
    }
  }
  .required {
    color: #BF311F;
    margin-right: 4px;
  }
}
</style>
