<template>
  <el-tree
    :style="{ height: treeHeight }"
    :default-expand-all="defaultExpandAll"
    :show-checkbox="showCheckBox"
    :data="list"
    :accordion="accordion"
    :default-expanded-keys="defaultExpandedKeys"
    :default-checked-keys="defaultCheckedKeys"
    :highlight-current="highlightCurrent"
    :node-key="nodeKey"
    @node-click="nodeClick"
    :expand-on-click-node="!highlightCurrent"
    :check-strictly="checkStrictly"
    :props="props"
    ref="tree">
  </el-tree>
</template>
<script>
export default {
  name: 'lzTree',
  props: {
    height: {
      default: 0,
      type: Number,
    },
    list: {
      default: () => [],
      type: Array,
    },
    showCheckBox: {
      default: false,
      type: Boolean,
    },
    defaultExpandAll: {
      default: false,
      type: Boolean,
    },
    nodeKey: {
      default: 'id',
      type: String,
    },
    props: {
      default: () => {},
      type: Object,
    },
    defaultExpandedKeys: {
      default: () => [],
      type: Array,
    },
    defaultCheckedKeys: {
      default: () => [],
      type: Array,
    },
    accordion: {
      default: true,
      type: Boolean,
    },
    checkStrictly: {
      default: false,
      type: Boolean,
    },
    highlightCurrent: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    treeHeight() {
      return this.height ? `${this.height}px` : 'auto';
    },
  },
  watch: {
    defaultCheckedKeys: function change(val) {
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(val);
      });
    },
  },
  data() {
    return {};
  },
  methods: {
    getCheckedKeys() {
      return this.$refs.tree.getCheckedKeys();
    },
    getHalfCheckedKeys() {
      return this.$refs.tree.getHalfCheckedKeys();
    },
    getCurrentKey() {
      return this.$refs.tree.getCurrentKey();
    },
    setCurrentKey(value) {
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(value);
      });
    },
    nodeClick(val) {
      this.$emit('nodeClick', val);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-tree {
  overflow-y: scroll;
}
</style>
