<template>
  <div
    class="sticky"
    data-flex="main:center cross:center"
    :style="{
      height: height+'px',
      zIndex: zIndex,
      bottom: stickyBottom + 'px',
      left,
      width,
    }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'lzSticky',
  props: {
    stickyBottom: {
      type: Number,
      default: 0,
    },
    zIndex: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      active: true,
      position: 'fixed',
      height: 50,
    };
  },
  computed: {
    isCollapse() {
      return this.$store.state.app.sidebar === 'mini';
    },
    width() {
      return `calc(100% - ${this.isCollapse ? '65px' : '210px'})`;
    },
    left() {
      return this.isCollapse ? '65px' : '210px';
    },
  },
};
</script>
<style lang="scss" scoped>
.sticky {
  padding: 10px 20px;
  background: #f6f8fa;
  border-top: 1px solid #EBEEF5;
  position: fixed;
  box-sizing: border-box;
  transition: all .3s;
}
</style>
