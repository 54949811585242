export default [
  {
    path: 'finance-ticket',
    component: () => import(/* webpackChunkName: "finance-ticket" */ '@/views/finance/ticket/index.vue'),
    name: 'finance-ticket',
    meta: { title: '出单统计', roles: ['/finance/ticket'] },
  },
  {
    path: 'finance-balance',
    component: () => import(/* webpackChunkName: "finance-balance" */ '@/views/finance/balance/index.vue'),
    name: 'finance-balance',
    meta: { title: '出单统计', roles: ['finance/balance'] },
  },
];
