<template>
  <div class="tabs" data-flex="cross:center">
    <el-tabs v-model="tabValue" type="card" @tab-remove="handleTabRemove">
      <el-tab-pane
        :closable="index != 0"
        :key="item.path"
        v-for="(item, index) in visitedMenu"
        :label="item.title"
        :name="item.path">
      </el-tab-pane>
    </el-tabs>
    <tabs-menu :value.sync="tabValue"></tabs-menu>
  </div>
</template>
<script>
export default {
  name: 'tabs',
  data() {
    return {
      tabValue: this.$route.fullPath,
    };
  },
  watch: {
    tabValue: function change(path) {
      this.$router.push({
        path,
      });
    },
    $route: function change() {
      this.getMenu();
      this.tabValue = this.$route.fullPath;
    },
  },
  computed: {
    visitedMenu() {
      return this.$store.state.app.visitedMenu;
    },
  },
  mounted() {
    this.getMenu();
  },
  methods: {
    getMenu() {
      this.$store.dispatch('app/setChangeTags', {
        name: this.$route.name,
        title: this.$route.meta.title,
        path: this.$route.fullPath,
        type: this.$route.meta.type || '',
      });
    },
    handleTabRemove(path) {
      const index = this.visitedMenu.findIndex((r) => r.path === path);
      console.log(index);
      if (this.visitedMenu[index].path === this.$route.fullPath) {
        this.$router.push({
          path: this.visitedMenu[index - 1].path,
        });
      }
      this.$store.dispatch('app/setCloseTags', {
        type: 'close',
        value: index,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  height: 50px;
  padding: 0 20px;
  border-top: 1px solid #f6f6f6;
  .el-tabs {
    width: calc(100% - 70px);
    margin-top: 4px;
  }
  ::v-deep .el-tabs__header {
    margin: 0;
    border: 0;
    .el-tabs__nav {
      border: 0;
    }
    .el-tabs__item {
      height: 38px;
      padding: 0 30px 0 30px;
      margin-top: 6px;
      margin-right: -15px;
      line-height: 38px;
      border: 0;
      transition: padding .3s cubic-bezier(.645,.045,.355,1);
      &.is-closable:hover,
      &.is-closable {
        padding: 0 30px;
      }
      &:hover,
      &.is-active {
        color: #515a6e;
        background: #dee1e6;
        padding: 0 30px !important;
        mask: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANoAAAAkCAYAAADvhSSyAAAACXBIWXMAAAsTAAALEwEAmpwYAAABbUlEQVR4nO3d4U3CUBSG4beJA9QNcAJhAtnAOoGOwCaOoBvoCDgBOIHdQDbAH7cJMaHtxcJB6/skJ6Thptw/H+cSktOCn5kCc+AWKJtraaxqYA28A8/N9cktgE9ga1n/tBYcqDhw/QtQHfoh0gitgdkpbrzg/N8klvWbKruz5Xa0CbAi/R6TtDMjdbdOF5k3qzBk0j4VRwzazbC9SKN1nbMo9+j4QTo+SvpuA1z2LcoN2nbYXqRR681R7tFR0gAGTQpg0KQABk0KYNCkAAZNCmDQpAAGTQpg0KQABk0KYNCkAAZNCmDQpAAGTQpg0KQABk0KYNCkAAZNGq4kjTRolRM0x31L3Sb0TMLKCZqTiaVu9/QErW+oyAQHp0p9NqRBqnXbgq6glcATdjQpRw1ctb3ZFrQSeAQejr8fabRegbvcxXPScfHcDxCwrL9YK/Y0qILdgwSnpNHfHhWl4ZbAW/O6LEgplHRC/mEtBfgClkhxraFbr7gAAAAASUVORK5CYII=);
        mask-size: 100% 100%;
      }
      &.is-active {
        color: #009688;
        background: rgba(24,144,255,.1);
      }
    }
  }
}
</style>
<style lang="scss" scoped></style>
