import request from '@/utils/request';

// 用户信息
export function account() {
  return request({
    url: '/admin/account',
    method: 'get',
    noLoading: true,
  });
}

// 菜单
export function menu() {
  return request({
    url: '/auth-rule/menu',
    method: 'get',
    noLoading: true,
  });
}

// 通用下拉选择
export function constant() {
  return request({
    url: '/common/constant',
    method: 'get',
    noLoading: true,
  });
}
