import Vue from 'vue';
import VueRouter from 'vue-router';

import layout from '@/layout/index.vue';

Vue.use(VueRouter);

export const asyncRouterMap = [
  {
    path: '',
    component: layout,
    redirect: 'index',
    name: 'index-layout',
    alwaysShow: true,
    meta: { title: '首页', icon: 'ri-home-2-line' },
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/index.vue'),
        meta: { title: '首页' },
      },
    ],
  },
];

const requireComponent = require.context('./moudles', true, /.js$/);
requireComponent.keys().forEach((key) => {
  const componentConfig = requireComponent(key).default;
  asyncRouterMap.push(...componentConfig);
});

const createRouter = () => new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
      name: 'login',
      meta: { noLogin: true },
    },
    {
      path: '/doc',
      component: () => import(/* webpackChunkName: "doc" */ '@/views/doc/index.vue'),
      name: 'doc',
      meta: { title: '帮助文档' },
    },
  ],
});

const route = createRouter();

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err); // eslint-disable-line
};

export default route;
