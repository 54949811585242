import Vue from 'vue';
import '@/layout/components'; // 布局文件
import elePlugn from '@/utils/index'; // 封装element通用插件
import dragDialog from '@/directive/dragDialog'; // element dialog 拖拽
import '@/utils/permission'; // 权限判断
import './element'; // element组建

Vue.use(elePlugn);
Vue.use(dragDialog);

// 通用组建
const requireComponent = require.context('../components/', true, /\.vue$/);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = componentConfig.default.name;
  if (componentName) Vue.component(componentName, componentConfig.default || componentConfig);
});
