import NProgress from 'nprogress';
import router from '@/router';
import { getAccessToken } from '@/utils/auth';
import 'nprogress/nprogress.css';
import store from '@/store';

NProgress.configure({ showSpinner: false });

router.beforeEach((to, from, next) => {
  NProgress.start();
  // document.title = to.meta.title || '后台';
  if (getAccessToken()) {
    if (to.path === '/login') {
      next({ path: '/' });
      NProgress.done();
      return;
    }
    if (!store.state.user.info.id) {
      store.dispatch('user/setInfo').then((roles) => {
        store.dispatch('user/generateRoutes', { roles }).then(() => {
          router.addRoutes(store.state.user.router);
          next({ ...to, replace: true });
        });
      });
      return;
    }
    next();
  } else {
    if (to.meta.noLogin) {
      next();
      return;
    }
    next({
      path: '/login',
      query: { callback: to.fullPath },
    });
    NProgress.done();
  }
});

router.afterEach(() => {
  NProgress.done();
});
