<template>
  <div class="lz-dialog-table">
    <lz-search
      :is-router="false"
      :form="search">
    </lz-search>
    <lz-table-list
      :search="search"
      :table="table">
    </lz-table-list>
    <lz-pagination
      v-if="isPage"
      class="mt-20"
      layout="total, prev, pager, next"
      :is-router="false">
    </lz-pagination>
  </div>
</template>
<script>
export default {
  name: 'lzDialogTable',
  props: {
    search: {
      type: Array,
      default: () => [],
    },
    table: {
      type: Array,
      default: () => [],
    },
    // 是否分页显示
    isPage: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    console.log('load');
  },
};
</script>
