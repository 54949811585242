/**
 * @name: 表单获取值
 * @param {formData} {Array} 表单数组
 */

export function formGetValue(formData = []) {
  const params = {};
  const error = [];
  formData.forEach((element) => {
    params[element.fieldName] = element.value === null ? '' : element.value.toString();
    if (element.type === 'upload' && Array.isArray(element.value)) {
      params[element.fieldName] = element.value.map((r) => r.id).toString();
    }
    if (element.type === 'cascader') { // 级联直接传数据
      params[element.fieldName] = element.value;
    }
    // 如果包含||确定为数组
    if (element.fieldName.indexOf('||') >= 0) {
      const names = element.fieldName.split('||');
      names.forEach((key, index) => {
        const val = Array.isArray(element.value) ? element.value[index] : '';
        params[key] = val !== undefined && val !== null ? val : '';
      });
      delete params[element.fieldName];
    }
    // 如果是数组并且是必填
    if (element.required && Array.isArray(element.value) && element.value.length === 0 && !element.hide) error.push(element);
    if (element.required && params[element.fieldName] === '' && !element.hide) error.push(element);
  });
  return { params, error };
}

export function getFileType(url) {
  if (!url) return '';
  const suffix = url.split('.');
  const list = {
    jpg: 'image',
    png: 'image',
    jpeg: 'image',
    bmp: 'image',
    gif: 'image',
    tif: 'image',
    doc: 'word',
    docx: 'word',
    xls: 'excel',
    xlsx: 'excel',
    pdf: 'pdf',
    txt: 'txt',
    zip: 'zip',
    rar: 'zip',
    '7z': 'zip',
  };
  return list[suffix[suffix.length - 1].toLowerCase()] || 'other';
}
