export default [
  {
    path: 'goods-list',
    component: () => import(/* webpackChunkName: "goods-list" */ '@/views/product/index.vue'),
    name: 'goods-list',
    meta: { title: '产品管理', roles: ['goods-list'], clearSearch: true },
  },
  {
    path: 'goods-list-create',
    component: () => import(/* webpackChunkName: "goods-list-create" */ '@/views/product/create.vue'),
    name: 'goods-list-create',
    meta: { title: '产品新增', roles: ['goods-list'], type: 'create' },
  },
  {
    path: 'goods-list-view',
    component: () => import(/* webpackChunkName: "goods-list-view" */ '@/views/product/detail.vue'),
    name: 'goods-list-view',
    meta: { title: '产品详情', roles: ['goods-list'], type: 'view' },
  },
  {
    path: 'goods-brand',
    component: () => import(/* webpackChunkName: "goods-brand" */ '@/views/brand/index.vue'),
    name: 'goods-brand',
    meta: { title: '品牌管理', roles: ['goods-brand'] },
  },
  {
    path: 'goods-cate',
    component: () => import(/* webpackChunkName: "cate" */ '@/views/cate/index.vue'),
    name: 'goods-cate',
    meta: { title: '品类管理', roles: ['goods-cate'] },
  },
  {
    path: 'goods-price-range',
    component: () => import(/* webpackChunkName: "price-range" */ '@/views/price-range/index.vue'),
    name: 'goods-price-range',
    meta: { title: '价格区间管理', roles: ['goods-price-range'] },
  },
  {
    path: 'goods-purpose',
    component: () => import(/* webpackChunkName: "purpose" */ '@/views/purpose/index.vue'),
    name: 'goods-purpose',
    meta: { title: '用途管理', roles: ['goods-purpose'] },
  },
  {
    path: 'goods-price-title',
    component: () => import(/* webpackChunkName: "price-title" */ '@/views/price-title/index.vue'),
    name: 'goods-price-title',
    meta: { title: '礼一价格', roles: ['goods-price-title'] },
  },
  {
    path: 'goods-scene',
    component: () => import(/* webpackChunkName: "scene" */ '@/views/scene/index.vue'),
    name: 'goods-scene',
    meta: { title: '应用场景管理', roles: ['goods-scene'] },
  },
  {
    path: 'goods-stock',
    component: () => import(/* webpackChunkName: "stock" */ '@/views/stock/index.vue'),
    name: 'goods-stock',
    meta: { title: '库存管理', roles: ['goods-stock-add'] },
  },
  {
    path: 'goods-spec',
    component: () => import(/* webpackChunkName: "spec" */ '@/views/spec/index.vue'),
    name: 'goods-spec',
    meta: { title: '规格管理', roles: ['goods-spec'] },
  },
];
