<template>
  <el-dialog
    append-to-body
    ref="dialog"
    top="2.5%"
    :visible.sync="dialogVisible"
    :fullscreen="fullscreen"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :width="width + 'px'"
    :title="title">
      <!-- header -->
      <div
        slot="title"
        class="lz-dialog__header"
        data-flex="main:justify cross:center">
        <span class="lz-dialog__title">{{ title }}</span>
        <div class="lz-dialog__menu">
          <i
            :class="fullscreen ? 'ri-fullscreen-exit-line' : 'ri-fullscreen-fill'"
            @click.stop="handleFullScreen">
          </i>
        </div>
      </div>
      <!-- body -->
      <div class="lz-dialog__body" data-flex-box="1"><slot /></div>
      <!-- footer -->
      <template v-if="isCloseBtn || dialogBtns.length > 0">
        <div slot="footer" class="lz-dialog__footer">
          <slot name="footer" />
          <template v-for="(element, index) in dialogBtns">
            <el-button
              :key="index"
              @click="handleDialogBtnClick(element.key)"
              :type="element.type || 'primary'">
              {{ element.name }}
            </el-button>
          </template>
          <el-button
            v-if="isCloseBtn"
            @click="dialogVisible = false">
            取 消
          </el-button>
        </div>
      </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'lzDialog',
  props: {
    title: {
      type: String,
      default: '弹出框',
    },
    width: {
      type: Number,
      default: 1000,
    },
    dialogBtns: {
      type: Array,
      default: () => [],
    },
    isCloseBtn: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    dialogVisible: function change(val) {
      this.$emit('change-dialog-visible', val);
    },
  },
  data() {
    return {
      dialogVisible: false,
      fullscreen: false,
    };
  },
  methods: {
    handleFullScreen() {
      // 防止因为拖拽导致全屏出现问题
      // const yxDialog = this.$refs.dialog;
      // yxDialog.style.left = 0;
      // yxDialog.style.top = 0;
      this.fullscreen = !this.fullscreen;
    },
    /**
     * 弹窗按钮点击事件
     * @param {type} {String} 类型
    */
    handleDialogBtnClick(type) {
      this.$emit('dialog-btn-click', type);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  max-height: 90%;
  overflow: hidden;

  .el-card:last-child {
    margin-bottom: 0;
  }

  &.is-fullscreen {
    max-height: 100%;
  }

  .el-dialog__header {
    padding: 20px;
    border-bottom: 1px solid #ebeef5;
  }

  .el-dialog__body {
    flex: 1;
    overflow-y: auto;
  }

  .el-dialog__footer {
    border-top: 1px solid #ebeef5;
  }
}

.lz-dialog__menu {
  padding-right: 20px;

  i {
    color: #909399;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: #009688;
    }
  }
}
</style>
