/* eslint no-param-reassign: 0 */
import { MessageBox, Loading } from 'element-ui';
import route from '@/router';
import store from '@/store';
import request from '@/utils/request';

const install = (Vue) => {
  /* 全局加载层 */
  Vue.prototype.$baseLoading = (text) => {
    const loading = Loading.service({
      lock: true,
      text: text || '数据处理中',
      background: 'rgba(0, 0, 0, .7)',
    });
    return loading;
  };

  /* 全局Alert */
  Vue.prototype.$baseAlert = (ops = {}) => {
    const {
      content,
      title,
      showClose = false,
      confirmText,
      callback,
    } = ops;
    MessageBox.alert(content, title || '温馨提示', {
      confirmButtonText: confirmText || '确定',
      dangerouslyUseHTMLString: true,
      showClose,
      callback: () => {
        if (callback) callback();
      },
    });
  };
  /* 全局Confirm */
  Vue.prototype.$baseConfirm = (ops = {}) => {
    const {
      content,
      title,
      confirmText,
      cancalText,
      callConfirm,
      callCancel,
    } = ops;
    MessageBox.confirm(content, title || '温馨提示', {
      confirmButtonText: confirmText || '确定',
      cancelButtonText: cancalText || '取消',
      closeOnClickModal: false,
      distinguishCancelAndClose: true,
      type: 'warning',
    }).then(() => {
      if (callConfirm) callConfirm();
    }).catch((action) => {
      if (callCancel) callCancel(action);
    });
  };
  /* 全局Prompt */
  Vue.prototype.$basePrompt = (ops = {}) => {
    const {
      content,
      title,
      inputType,
      confirmText,
      cancalText,
      errorText,
      pattern,
      callConfirm,
      callCancel,
    } = ops;
    MessageBox.prompt(content, title || '温馨提示', {
      confirmButtonText: confirmText || '确定',
      cancelButtonText: cancalText || '取消',
      inputPattern: pattern || /\S/,
      inputType: inputType || 'textarea',
      closeOnClickModal: false,
      closeOnPressEscape: false,
      distinguishCancelAndClose: true,
      inputPlaceholder: '请输入内容',
      inputErrorMessage: errorText || '内容不能为空',
    }).then(({ value }) => {
      if (callConfirm) callConfirm(value);
    }).catch((action) => {
      if (callCancel) callCancel(action);
    });
  };
  /* 全局设置标题 */
  Vue.prototype.$baseTitle = (title) => {
    const current = route.history.current;
    const visitedMenu = store.state.app.visitedMenu;
    const index = visitedMenu.findIndex((r) => r.path === current.fullPath);
    visitedMenu[index].title = String(title.length > 8 ? `${title.substring(0, 8)}...` : title);
    // document.title = title;
  };
  /* 全局关掉当前标签 */
  Vue.prototype.$baseCloseTag = () => {
    const current = route.history.current;
    const visitedMenu = store.state.app.visitedMenu;
    const index = visitedMenu.findIndex((r) => r.path === current.fullPath);
    visitedMenu.splice(index, 1);
  };
  /* 全局接口调用 */
  Vue.prototype.$baseHttp = request;

  /* 全局json转url */
  Vue.prototype.$jsonToUrl = (params) => Object.keys(params).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');

  /* 深度拷贝 */
  Vue.prototype.$baseDeepClone = (value) => JSON.parse(JSON.stringify(value));
  Vue.prototype.$baseSetButtonText = (element, type, name) => {
    if (element.htmlButton.length === 0) return;
    const target = element.htmlButton.find((r) => r.key === type);
    if (target) Vue.set(target, 'name', name);
  };
};

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default install;
