<template>
  <div class="layout-main">
    <el-container>
      <el-aside
        :style="{ width: asideWidth }"
        class="asidebar-container"
        :class="`asidebar-container_${sidebar}`">
        <aside-bar></aside-bar>
      </el-aside>
      <el-container>
        <el-header class="layout-header" data-flex="dir:top">
          <head-bar data-flex-box="1"></head-bar>
          <tabs></tabs>
        </el-header>
        <el-main>
          <div id="router-view">
            <transition name="fade-transform" mode="out-in">
              <keep-alive :include="cachedViews">
                <router-view class="router-view" :key="key" />
              </keep-alive>
            </transition>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    cachedViews() {
      return this.$store.state.app.cachedViews;
    },
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    asideWidth() {
      return this.sidebar === 'mini' ? '65px' : '210px';
    },
    key() {
      const route = this.$route;
      // const isDetail = route.meta.type && route.meta.type === 'detail';
      return route.meta.type ? route.fullPath : route.path;
    },
  },
  mounted() {
    // this.$baseHttp.get('/agent/warning/list');
  },
};
</script>
<style lang="scss" scoped>
.layout-main,
.el-container {
  height: 100%;
}

.asidebar-container {
  height: 100%;
  background-color: #000;
  transition: all 0.5s;
  overflow-x: hidden;
  box-shadow: 2px 0 6px rgba(0,21,41,.3);
}

.layout-header {
  height: 110px !important;
  padding: 0px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
}

#router-view {
  display: flex;
  position: relative;
  // min-height: 100%;
  min-height: calc(100vh - 110px - 40px);
}

.router-view {
  display: flex;
  flex: 1;
  min-height: 100%;
  flex-direction: column;
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  overflow-x: hidden;
}

::v-deep .el-menu--collapse {
  width: 65px;
  i {
    margin-right: 0 !important;
  }
  .el-submenu__title span,
  .el-menu-item span {
      height: 0;
      width: 0;
      overflow: hidden;
      visibility: hidden;
      display: inline-block;
  }
  .el-submenu__icon-arrow {
    display: none;
  }
}

::v-deep .el-menu {
  [class^="ri-"] {
    vertical-align: middle;
    width: 24px;
    text-align: center;
    font-size: 18px;
    margin-right: 5px;
  }
  .el-menu-item,
  .el-submenu__title {
    height: 50px;
    overflow: hidden;
    line-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.el-menu--popup .el-menu-item:hover,
  .el-menu-item.is-active,
  .el-menu-item:hover {
    color: #fff !important;
    background-color: #009688!important;
    i {
      color: #fff;
    }
  }
}
</style>
