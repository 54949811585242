<template>
  <div>
    <template v-if="element.multiple">
      <draggable
        class="drag-img-list"
        :list="element.value"
        v-bind="{group: 'upload', ghostClass: 'ghost', animation: 200}"
        :no-transition-on-drag="true">
        <div class="lz-upload" v-for="(item, index) in element.value" :key="index">
          <img :src="item.pic" v-if="getFileType(item.file) == 'image'" />
          <img v-else :src="getOtherUrl(item)" />
          <div class="action">
            <i class="el-icon-search" v-if="getFileType(item.file) == 'image'" @click="handlePreview(index)"></i>
            <i class="el-icon-download" @click="handleOpen(item.file)" v-else></i>
            <i class="el-icon-delete" v-if="!disabled" @click="handleDelete(index)"></i>
          </div>
        </div>
      </draggable>
    </template>
    <div class="lz-upload" @click="handleFileClick">
      <template v-if="!element.multiple && element.value && element.value.length > 0">
        <img :src="element.value[0].pic" v-if="getFileType(element.value[0].file) == 'image'" />
        <img v-else :src="getOtherUrl(element.value[0])" />
        <div class="action">
          <i class="el-icon-search" v-if="getFileType(element.value[0].file) == 'image'" @click.stop="handlePreview(0)"></i>
          <i class="el-icon-download" @click="handleOpen(element.value[0].file)" v-else></i>
          <i class="el-icon-delete" v-if="!disabled" @click.stop="handleDelete(0)"></i>
        </div>
      </template>
      <input @change="handleFileChange" ref="file" type="file" name="file" :multiple="element.multiple" class="el-upload__input">
      <i class="el-icon-plus" v-if="(element.multiple || (!element.multiple && element.value.length == 0))"></i>
      <el-progress
        v-if="progressVisible"
        class="progress"
        :status="total == 100 ? 'success' : 'exception'"
        type="circle"
        :percentage="total">
      </el-progress>
    </div>
    <el-image-viewer
      v-if="showViewer"
      :url-list="srcList"
      :zIndex="9999"
      :initialIndex="initialIndex"
      :on-close="handleCloseViewer"  />
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'; // eslint-disable-line
import { getFileType } from '@/utils/form';

export default {
  name: 'lzUpload',
  components: {
    ElImageViewer,
    draggable,
  },
  props: {
    element: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getFileType,
      progressVisible: false,
      total: 0,
      showViewer: false,
      srcList: [],
      initialIndex: 0,
    };
  },
  methods: {
    handleFileChange(e) {
      if (e.target.value === '' || this.disabled) return;
      this.progressVisible = true;
      const file = e.target.files;
      const form = new FormData();
      Object.keys(file).forEach((v) => {
        form.append('files[]', file[v]);
      });
      // 加水印地址
      if (['goods-list-view', 'goods-list-create'].includes(this.$route.name)) {
        console.log(this.$route.name);
        form.append('is_water', 1);
      }
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const complete = progressEvent.loaded / progressEvent.total;
          this.total = Number((complete * 100));
        },
        timeout: 0,
        noLoading: true,
      };
      this.$baseHttp.post('/attachment/upload', form, config).then((response) => {
        this.progressVisible = false;
        this.total = 0;
        this.$refs.file.setAttribute('type', 'text');
        this.$refs.file.setAttribute('type', 'file');
        if (!response) return;
        if (this.element.multiple) {
          this.element.value.push(...response.data);
        } else {
          this.$set(this.element, 'value', response.data);
        }
        this.$message.success('上传成功');
      });
    },
    handleFileClick() {
      if (this.disabled) return;
      this.$refs.file.click();
    },
    handleDelete(index) {
      this.$baseConfirm({
        content: '是否确定删除',
        callConfirm: () => {
          this.element.value.splice(index, 1);
        },
      });
    },
    handlePreview(index) {
      this.srcList = this.element.value.filter((r) => r.pic).map((r) => r.file);
      this.showViewer = true;
      this.initialIndex = index;
    },
    handleCloseViewer() {
      this.showViewer = false;
    },
    handleOpen(url) {
      window.open(url);
    },
    getOtherUrl(item) {
      // if (process.env.NODE_ENV !== 'development') {
      //   return `/public/accessory/${this.getFileType(item.file)}.png`;
      // }
      return `/accessory/${this.getFileType(item.file)}.png`;
    },
  },
};
</script>
<style lang="scss" scoped>
.drag-img-list {
  display: inline-block;
}
</style>
