<template>
  <div>
    <lz-card title="产品列表">
      <template v-for="(element, index) in selectGoods">
        <items
          @click-product="handleClickProduct"
          @preview-product="handlePreview"
          :index="index"
          :list="goods"
          :element="element"
          :key="index">
        </items>
      </template>
    </lz-card>
    <lz-card  class="mt-20" title="新增产品">
      <el-button type="primary" slot="right" @click="$refs.productList.init()">新增</el-button>
      <template v-for="(element, index) in addGoods">
        <items
          @click-product="handleClickProduct"
          @preview-product="handlePreview"
          :index="index"
          :list="goods"
          :element="element"
          :key="index">
        </items>
      </template>
    </lz-card>
    <lz-card title="兑换列表" v-if="goods.length > 0">
      <el-table
        :data="goods"
        :header-cell-style="{backgroundColor: '#f6f8fa'}"
        border>
        <el-table-column prop="title" label="产品"></el-table-column>
        <el-table-column width="200px" label="规格名称">
          <template slot-scope="scope">
            <img
              v-if="scope.row.spec.pic"
              :src="scope.row.spec.pic"
              style="width: 50px; height: 50px; display: inline-block;vertical-align: middle; margin-right: 10px;" />
            {{ scope.row.spec.memo || scope.row.spec.name }}
          </template>
        </el-table-column>
        <el-table-column prop="exchange_num" label="兑换个数"></el-table-column>
        <el-table-column prop="nums" label="几次发货"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              class="list-button"
              @click="handleDelete(scope.row.id)"
              type="danger">
              删除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
    </lz-card>
    <lz-dialog
      title="选择规格"
      :width="400"
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      ref="lzDialog">
      <div v-if="pic" style="margin: 0 0 10px 100px"><img :src="pic" width="100" height="100" style="border: 1px solid #DCDFE6; padding: 5px;" /></div>
      <lz-form
        @change-select="handleChangeSelect"
        :form="form"
        :col="1"
        ref="lzForm">
      </lz-form>
    </lz-dialog>
    <product-list
      :list="[
        ...this.selectGoods,
        ...this.addGoods,
      ]"
      :info="info"
      :goods="addGoods"
      ref="productList">
    </product-list>
  </div>
</template>
<script>
import config from '@/config';
import items from './items';
import productList from './productList';

export default {
  name: 'lzSelectProduct',
  components: {
    items,
    productList,
  },
  props: {
    info: {
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
    exchange: {
      type: Number,
      default: 0,
    },
    goods: {
      type: Array,
      default: () => [],
    },
    out: {
      type: Array,
      default: () => [],
    },
    selectGoods: {
      type: Array,
      default: () => [],
    },
    addGoods: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    disabled() {
      const target = this.form;
      if (target.list.length === 0) {
        return target.nums === 0;
      }
      return target.spec_id === '' || target.nums === '';
    },
    pic() {
      const spec = this.form.find((r) => r.fieldName === 'spec_id');
      if (spec.value === '' || spec.options.length === 0) return null;
      const target = spec.options.find((r) => r.value === spec.value);
      return target ? target.pic : null;
    },
  },
  data() {
    return {
      clickObj: null,
      form: [
        { fieldName: 'spec_id', type: 'select', name: '规格', required: true, value: '', options: [] },
        { fieldName: 'nums', type: 'select', name: '发货次数', required: true, value: '', options: [], hide: true },
        { fieldName: 'exchange_num', type: 'number', name: '兑换数量', required: true, value: '', min: 1 },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
    };
  },
  methods: {
    handleClickProduct(element) {
      this.clickObj = element;
      const list = [
        ...this.selectGoods,
        ...this.addGoods,
      ];
      const target = list.find((v) => v.id === element.id);
      // 断货不让兑换
      const isOut = this.out.indexOf(element.id) >= 0;
      if (isOut) return;
      // 超出兑换限制不让兑换
      if ((this.total + 1) > this.exchange) {
        this.$message.error(`您只能兑换${this.exchange}个产品`);
        return;
      }
      // 如果当前只能兑换1个且没有规格 直接添加产品产品
      if ((this.exchange - this.total === 1) && target.spec.length === 0) {
        this.goods.push({
          id: target.id,
          title: target.title,
          spec_name: '无',
          exchange_num: 1,
          spec_id: '',
          spec: {
            memo: '无',
          },
          nums: 1,
          price_title_price: target.price_title.price,
        });
        return;
      }
      this.form.forEach((parent) => {
        if (parent.fieldName === 'spec_id') {
          this.$set(parent, 'options', target.spec.map((r) => ({
            ...r,
            label: r.memo || r.name,
            value: String(r.id),
          })));
        }
        if (parent.fieldName === 'nums') {
          this.$set(parent, 'hide', true);
        }
        if (parent.fieldName === 'exchange_num') {
          this.$set(parent, 'max', this.exchange - this.total);
        }
        this.$set(parent, 'value', '');
      });
      this.$refs.lzDialog.dialogVisible = true;
    },
    handleChangeSelect(element) {
      if (element.fieldName !== 'spec_id') return;
      const nums = this.form.find((r) => r.fieldName === 'nums');
      const specNums = element.options.find((r) => r.value === element.value);
      this.$set(nums, 'value', '');
      this.$set(nums, 'hide', !specNums);
      if (!specNums) return;
      this.$set(nums, 'options', specNums.nums.map((r) => ({
        label: r,
        value: r,
      })));
      if (specNums.nums.length === 1) {
        this.$set(nums, 'value', String(specNums.nums[0]));
      }
    },
    async handleCommonBtnCLick(type) {
      if (type !== 'save') {
        return;
      }
      const params = this.$refs.lzForm.getValue();
      if (!params) return;
      // 判断产品是否重复 重复数量累加
      const target = this.goods.find((r) => r.id === this.clickObj.id);
      if (target
        && target.spec_id === params.spec_id
        && target.nums === params.nums) {
        target.exchange_num += params.exchange_num;
        this.$refs.lzDialog.dialogVisible = false;
        return;
      }
      const spec = this.form.find((r) => r.fieldName === 'spec_id');
      this.goods.push({
        id: this.clickObj.id,
        title: this.clickObj.title,
        exchange_num: params.exchange_num,
        spec_id: params.spec_id,
        spec: spec.options.find((r) => r.value === params.spec_id) || {},
        spec_name: spec.options.length > 0 ? spec.options.find((r) => r.value === params.spec_id).name : '无',
        nums: params.nums || 1,
        price_title_price: this.clickObj.price_title.price,
      });
      this.$refs.lzDialog.dialogVisible = false;
    },
    handleDelete(id) {
      const index = this.goods.findIndex((r) => r.id === id);
      if (index === -1) return;
      this.goods.splice(index, 1);
    },
    handlePreview(id) {
      window.open(`${config.giftUrl}/details/${id}`);
      // this.$emit('preview-product', id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
