export default [
  {
    path: 'order-list',
    component: () => import(/* webpackChunkName: "order-list" */ '@/views/order/index.vue'),
    name: 'order-list',
    meta: { title: '兑换详情', roles: ['order-list', 'order-shipping'], clearSearch: true, slideSearch: true },
  },
  {
    path: 'order-view',
    component: () => import(/* webpackChunkName: "order-detail" */ '@/views/order/details.vue'),
    name: 'order-view',
    meta: { title: '兑换详情', roles: ['order-detail'], type: 'detail' },
  },
];
