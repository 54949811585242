import { account, menu, constant } from '@/api/customer';
import layout from '@/layout/index.vue';
import childrenLayout from '@/layout/childrenLayout.vue';
import { asyncRouterMap } from '@/router';
import { removeAccessToken } from '@/utils/auth';
import errorPage from '@/views/error/404';
import store from '@/store';

function filterAsyncChildren(children, router, basePath) {
  children.forEach((el) => {
    const element = {
      name: el.code,
      path: el.code,
      meta: {
        title: el.title,
        roleBtn: el.btn || {},
        clearSearch: false,
        slideSearch: false,
      },
      children: [],
    };
    if (['ticket-public', 'ticket-birth', 'ticket-thank', 'ticket-list-recharge', 'ticket-list-fx'].includes(el.code)) {
      const ticketList = children.find((r) => r.code === 'ticket-list');
      if (ticketList) element.meta.roleBtn = ticketList.btn;
    }
    if (['code-recharge'].includes(el.code)) {
      const codeList = children.find((r) => r.code === 'code-list');
      if (codeList) element.meta.roleBtn = codeList.btn;
    }
    const path = `${basePath}/${el.code}`;
    const routerMap = asyncRouterMap.find((r) => r.meta.roles && r.meta.roles.includes(String(el.code)) && !r.meta.type);
    if (routerMap && routerMap.meta.clearSearch) {
      element.meta.clearSearch = true;
    }
    if (routerMap && routerMap.meta.slideSearch) {
      element.meta.slideSearch = true;
    }
    element.component = routerMap ? routerMap.component : errorPage;
    const linkage = asyncRouterMap.filter((r) => r.meta.roles && r.meta.roles.includes(String(el.code)) && r.meta.type);
    if (linkage.length > 0) {
      linkage.forEach((r) => {
        const linkageName = `${el.code}-${r.meta.type}`;
        const linkageType = r.meta.type;
        router.children.push({
          name: linkageName,
          path: linkageName,
          hidden: true,
          component: r.component,
          meta: {
            ...r.meta,
            activeMenu: path,
            title: `${el.title}${linkageType === 'create' ? '新增' : ''}`,
          },
        });
        // 设置keepAlive
        if (r.meta.type === 'create') {
          store.dispatch('app/setCache', linkageName);
        }
      });
    }
    if (el.children && el.children.length > 0) {
      element.component = childrenLayout;
      element.redirect = 'noRedirect';
      element.children = [];
      filterAsyncChildren(el.children, element, path);
    }
    router.children.push(element);
  });
}
const state = () => ({
  info: {},
  router: {},
  constant: {},
});

const getters = {
  info: (state) => state.info,
  router: (state) => state.router,
  constant: (state) => state.constant,
};

const mutations = {
  INFO_INIT: (state, value) => {
    state.info = value;
  },
  SET_ROUTERS: (state, value) => {
    state.router = value;
  },
  SET_CONSTANT: (state, value) => {
    const target = value;
    Object.keys(value).forEach((key) => {
      target[key] = value[key].map((r) => ({
        ...r,
        label: r.name,
        value: r.id,
      }));
    });
    // 载体类型
    target.carriertype = [
      { label: '实体卡', value: '1' },
      { label: '电子卡', value: '2' },
    ];
    // 销售渠道
    target.saleType = [
      { label: '直客', value: '1' },
      { label: '分销', value: '2' },
    ];
    // 结算方式
    target.settlemenType = [
      { label: '日结', value: '1' },
      { label: '周结', value: '2' },
      { label: '月结', value: '3' },
      { label: '半年结', value: '4' },
      { label: '年结', value: '5' },
    ];
    state.constant = target;
  },
};

const actions = {
  setInfo({ commit }) {
    return new Promise((resolve) => {
      Promise.all([
        account(),
        menu(),
        constant(),
      ]).then((response) => {
        commit('INFO_INIT', response[0].data);
        commit('SET_CONSTANT', response[2].data);
        resolve(response[1].data);
      });
    });
  },
  generateRoutes({ commit }, { roles }) {
    return new Promise((resolve) => {
      const accessedRouters = [];
      roles.forEach((el, index) => {
        const router = {
          path: `/${el.code}`,
          component: layout,
          name: `${el.code}_${index}`,
          redirect: 'noRedirect',
          meta: {
            title: `${el.title}`,
            icon: el.icon || 'ri-home-2-line',
          },
          children: [],
        };
        // 解决如果子级菜单没有分类
        if (!el.children || el.children.length === 0) {
          const child = JSON.parse(JSON.stringify(el));
          delete child.children;
          el.children = [child]; // eslint-disable-line
          router.alwaysShow = true;
          router.path = `/${el.code}`;
          router.name = `t${el.code}`;
        }
        if (['order'].includes(el.code)) {
          el.children.forEach((item) => {
            if (['order-shipping', 'order-list'].includes(item.code)) {
              item.btn = JSON.parse(JSON.stringify(el.btn)); // eslint-disable-line
            }
            if (['order-shipping'].includes(item.code)) {
              delete item.btn.sms; // eslint-disable-line
            }
          });
        }
        filterAsyncChildren(el.children, router, router.path);
        accessedRouters.push(router);
      });
      accessedRouters.unshift(asyncRouterMap[0]);
      accessedRouters.push({
        path: '*',
        redirect: '/404',
        hidden: true,
        meta: { noLogin: true },
      });
      commit('SET_ROUTERS', accessedRouters);
      resolve(accessedRouters);
    });
  },
  setLogout({ commit }) {
    return new Promise((resolve) => {
      removeAccessToken();
      commit('INFO_INIT', {});
      resolve('');
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
